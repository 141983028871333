@media (max-width: 1365px) {
      .container {
            max-width: 100%;
      }

      .header-nav-main .header-nav-link {
            font-size: 15px;
            padding: 0;
      }

      .home-hero-main,
      .container {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
      }

      .header-right-main {
            grid-column-gap: 10px;
            grid-row-gap: 10px;
      }

      .b-hide.glass-bgs .home-hero-main {
            padding: 15px;
      }
}

@media (max-width: 1180px) {
      .container {
            padding: 0 20px;
      }

      .presale-wrape {
            padding: 1.5rem;
      }

      .presale-wrape .gray-pad {
            padding: 18px 1.25rem;
      }

      .countdown-wrapper {
            padding: 1.5rem;
      }

      .countdown-wrapper .time-section .time {
            font-size: 1.75rem;
      }

      .header-nav-main .header-nav-link {
            font-size: 14px;
      }

      .one.home-hero-main,
      .one.home-hero-main .container {
            flex-wrap: wrap;
            justify-content: space-around;
      }

      .one .hero-text-area {
            width: calc(100% - 470px);
            order: 2;
      }

      .one .presale-wrape {
            width: 440px;
            max-width: 440px;
            order: 1;
      }

      .one .countdown-wrapper {
            width: 450px;
            max-width: 450px;
            order: 3;
      }

      .b-hide.glass-bgs .home-hero-main {
            overflow-y: auto;
            height: 95vh;
      }

      .b-hide.glass-bgs .home-hero-main .glass-bg {
            bottom: -150px;
      }

      .b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame .frame.faq {
            height: 105%;
            padding-bottom: 5%;
      }

      .home-hero-main.animation-hide {
            display: flex;
      }

}

@media (max-width: 1100px) {
      .chart-wrape {
            height: calc(100% - 370px);
      }

      /* .chart-wrape .chart-section {
            height: calc(100% - 110px);
      } */
      .chart-wrape .chart-section .App {
            height: 100%;
      }

      .echarts-for-react {
            min-height: 90%;
      }

      .echarts-for-react>div {
            transform: scale(1.35);
            margin-top: 55px;
      }

      .title-wrapes {
            margin-bottom: 20px;
            margin-top: 10px;
      }

      .title-wrapes h1 {
            font-size: 24px;
      }

      .stat-box {
            gap: 20px;
      }

      .stat-box .stat {
            padding: 10px;
      }

      .stat-box h6 {
            font-size: 22px;
      }

      .stat-box p {
            font-size: 13px;
      }

      .stat-box .claim-lable {
            padding: 2px 10px 3px 10px;
      }

      .stat-box .p-currency {
            width: 38px;
            height: 38px;
      }

      .stat-box .text-wrape {
            width: calc(100% - 45px);
      }
}

@media (max-width: 1023px) {
      .header-right-main .auth-wrape {
            font-size: 0;
      }

      .header-right-main .auth-wrape a.header-login-link {
            display: none;
      }

      .home-hero-main {
            flex-wrap: wrap;
            position: relative;
      }

      .home-hero-main .controler.mobile-shows {
            display: flex;
            position: absolute;
            top: 20px;
            right: 10px;
      }

      .home-hero-main .mobile-shows {
            display: flex;
            bottom: 0;
      }

      .body-overflow .header-cust-col.navigation {
            left: 0;
            transition: left 0.15s ease-in-out 0.15s;
            overflow: auto;
      }

      .body-overflow .header-nav-main .header-nav-ul {
            flex-wrap: wrap;
            display: flex;
      }

      .body-overflow .overlay-wrape {
            display: block;
      }

      .mobilemenu-active .header-cust-col.navigation .header-nav-main {
            left: 0;
      }

      .header-cust-col.navigation .mobile-shows {
            display: flex;
      }

      .header-cust-col .sidebar-brand-wrape .mobile-menu-btn {
            min-width: 24px;
            padding: 0;
            margin: 0;
      }
}

@media (max-width: 1000px) {

      /* .hero-text-area {
            order: 2;
            width: 50%;
            max-width: 50%;
      } */
      .presale-wrape {
            max-width: 50%;
            width: 50%;
            order: 1;
      }

      .countdown-wrapper {
            order: 3;
            /* width: 50%;
            max-width: 50%; */
            margin-bottom: 30px;
      }

      .footer-wrape .footer-brand {
            width: 30%;
      }

      .footer-wrape .footer-menus {
            width: 70%;
      }

      .tall.b-hide.glass-bgs .container {
            max-width: 100%;
      }

      .b-hide.glass-bgs .home-hero-main .glass-bg {
            bottom: 0;
            width: 170%;
      }

      .echarts-for-react {
            transform: scale(1.25);
            margin-top: 100px;
      }
}

@media (max-width: 990px) {
      .sidebar-brand-wrape {
            display: flex;
            justify-content: space-between;
            align-items: center;
      }

      .home-hero-main.two::before {
            background: url(../images/jpg/bg-2.jpg) no-repeat 0% 0%;
            background-size: cover;
      }

      .header-cust-col.logo-wrape {
            display: flex;
            align-items: center;
      }

      .header-logo-box.full-logo {
            display: none;
      }

      .header-logo-box.symbol-logo {
            display: flex;
            background: var(--black);
            padding: 10px;
            border-radius: 10px;
            width: 48px;
            height: 48px;
      }

      .header-nav-main .header-nav-ul {
            flex-wrap: wrap;
            gap: 15px;
      }

      .header-nav-main .header-nav-li {
            border-bottom: 1px solid #f0f0f0;
            padding: 15px 10px 15px 0;
            margin-right: 0;
            flex-wrap: wrap;
      }

      .header-cust-col.navigation .header-nav-main {
            position: fixed;
            background: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
            padding: 15px;
            height: 100vh;
            z-index: 99;
            top: 0;
            width: 250px;
            left: -280px;
            transition: left 0.15s ease-in-out 0.15s;
      }

      .header-cust-col .mobile-menu-btn {
            display: flex;
            min-width: 48px;
      }

      .header-cust-col .mobile-menu-btn svg#burger-menu {
            width: 20px;
            height: 20px;
      }

      .footer-wrape .footer-down {
            flex-wrap: wrap;
      }

      .footer-wrape .footer-brand {
            width: 100%;
            margin-bottom: 30px;
      }

      .footer-wrape .footer-menus {
            width: 100%;
      }

      .stat-box .claim-lable span {
            font-size: 12px;
      }
}

@media (max-width: 850px) {

      /* .hero-text-area {
            width: 40%;
            height: 40%;
      } */
      .presale-wrape {
            width: 55%;
            max-width: 55%;
      }

      .b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame .frame.faq {
            align-items: flex-start !important;
      }

      .stat-box {
            margin-bottom: 20px;
            flex-wrap: wrap;
      }

      .stat-box .stat {
            width: calc(50% - 10px);
            margin-bottom: 0;
      }
}

@media (max-width: 767px) {

      .chart-img-section .chart-img {
            max-width: 90%;
      }

      .chart-detail-section {
            flex-direction: column;
      }

      .chart-detail-section .detail-col-1 {
            margin: 0 0px 0 0;
      }

      .main-iframe {
            width: auto !important;
      }

      /* body.div-clicked, .b-hide.glass-bgs .home-hero-main {
            overflow: hidden;
      } */
      .chart-wrape .chart-section .App {
            margin: 20px 0 !important;
      }

      .header-main {
            padding: 0;
      }

      .home-hero-main,
      .container {
            padding: 0.5rem 1rem;
      }

      .home-hero-main.two::before {
            background: url(../images/jpg/bg-2.jpg) no-repeat 87% 0%;
            background-size: cover;
      }

      .countdown-wrapper {
            width: 450px;
            max-width: 100%;
            order: 1;
      }

      .presale-wrape {
            order: 2;
            width: 450px;
            max-width: 100%;
      }

      .hero-text-area {
            order: 3;
            width: 100%;
            max-width: 100%;
      }

      .presale-wrape .text-wrape h6 {
            gap: 5px;
      }

      .how-to-buy-wrape .container {
            flex-wrap: wrap;
      }

      .how-to-buy-wrape .left-section {
            width: 100%;
      }

      .how-to-buy-wrape .head-section {
            padding: 0 0 30px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }

      .how-to-buy-wrape p.head-label {
            margin-top: 0px;
      }

      .how-to-buy-wrape .title-wrape {
            max-width: 280px;
            text-align: center;
      }

      .how-to-buy-wrape .title-wrape h4,
      .how-to-buy-wrape .title-wrape p {
            text-align: center;
            display: inline-block;
      }

      /* .b-hide.glass-bgs .header-main.is-sticky ~ .semi-parallax-wrape {
            padding: 73px 0 0;
      }
      .b-hide.glass-bgs .header-main.is-sticky ~ .semi-parallax-wrape .box-frame {
            height: calc(100vh - 150px);
      } */
      .b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.how-to-buy .how-to-buy-wrape .right-section {
            width: 100%;
      }

      /* .b-hide.glass-bgs .inner-section {
            height: 300px;
      } */
      .faq-wrape .head-section {
            padding: 0;
      }

      .faq-wrape .title-wrape p {
            margin-top: 0;
      }

      .faq-wrape {
            padding: 10px 0;
      }

      .b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame {
            height: 115%;
      }

      .b-hide.glass-bgs .one .hero-text-area {
            display: none;
      }

      .b-hide.glass-bgs .presale-wrape {
            order: 1;
      }

      .b-hide.glass-bgs .one .countdown-wrapper {
            order: 2;
      }

      .b-hide.glass-bgs .home-hero-main {
            padding-bottom: 100px;
      }

      .b-hide.glass-bgs .home-hero-main {
            background: url(../images/png/glassbg-full.png) no-repeat 0 0;
            background-size: cover;
      }

      .b-hide.glass-bgs .home-hero-main .glass-bg {
            display: none;
      }

      .footer-up .store-wrape {
            width: 180px;
      }

      .echarts-for-react>div {
            margin-top: 0;
      }

      .stat-box .stat {
            width: 100%;
            margin-bottom: 0;
      }

      .mac.safari .chart-wrape h4,
      .mac.safari .roadmap-wrape .title-wrape h4,
      .mac.safari .faq-wrape .title-wrape h4 {
            font-size: 25px !important;
      }

      .mac.safari .chart-wrape h4:after {
            width: 133px !important;
            bottom: -21px !important;
      }

      .mac.safari .roadmap-wrape .title-wrape h4:after {
            width: 134px !important;
            height: 39px !important;
      }

      .mac.safari.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-prev,
      .mac.safari.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-next {
            width: 40px !important;
            height: 40px !important;
      }

      .roadmap-wrape .controls .swiper-button-prev,
      .roadmap-wrape .controls .swiper-button-next {
            left: 10px;
            top: 28px;
      }

      .mac.safari .faq-wrape .title-wrape h4:after {
            width: 135px !important;
            bottom: -21px !important;
      }
}

@media (max-width: 650px) {
      .header-logo-box .header-logo {
            height: auto;
            max-width: 130px;
      }

      .header-cust-col .mobile-menu-btn {
            margin-left: 0px;
      }

      .header-main {
            padding: 8px 0;
      }

      .header-main .container {
            padding: 0 15px;
      }

      .countdown-wrapper {
            padding: 1rem;
      }

      .presale-wrape {
            padding: 1.5rem 1rem;
      }

      .echarts-for-react {
            transform: scale(1);
            margin-top: 0;
      }
}

@media (max-width: 480px) {
      .countdown-wrapper .time-section {
            width: 25%;
            overflow: hidden;
      }

      .countdown-wrapper .time-section .time {
            font-size: 16px;
      }

      .countdown-wrapper .time-section small {
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
      }

      .footer-up {
            flex-wrap: wrap;
      }

      .footer-up .store-wrape {
            margin-top: 30px;
      }

      .footer-wrape .footer-menus {
            flex-wrap: wrap;
      }

      .footer-wrape ul {
            width: 50%;
            margin-bottom: 30px;
      }

      .footer-copyright {
            flex-wrap: wrap;
      }

      .bottom-link-wrape {
            flex-wrap: wrap;
            margin-bottom: 30px;
            flex-direction: column;
            width: 100%;
            gap: 25px;
      }

      .bottom-link-wrape p {
            width: 100%;
      }

      .b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.footer {
            height: auto !important;
      }

      .semi-parallax-wrape .frame.chart .chart-img-wrape {
            flex-wrap: wrap;
            justify-content: center;
      }

      .b-hide.glass-bgs .roadmap-wrape .container {
            max-width: calc(100% - 40px);
      }

      .b-hide.glass-bgs .roadmap-wrape .swipper-main-wrape {
            padding: 0 20px;
      }

      .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__bottom {
            font-size: 20px !important;
            width: 24px !important;
            height: 13px !important;
            line-height: 22px !important;
      }

      .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__top {
            font-size: 20px !important;
            width: 24px !important;
            height: 19px !important;
            line-height: 27px !important;
      }

      .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
            font-size: 20px !important;
            width: 24px !important;
            font-family: var(--headfont) !important;
            height: 26px !important;
            padding-bottom: 22px !important;
      }

      .countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back {
            font-size: 16px !important;
            line-height: 18px !important;
      }

      .countdown-pad .size-medium .flip-countdown-piece .flip-countdown-title {
            font-size: 13px !important;
      }

      .countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before {
            height: 22px !important;
            font-size: 20px !important;
            line-height: 30px !important;
      }

      .frame.faq .vdo-frame {
            left: -150px;
      }

      .echarts-for-react>div {
            transform: scale(1);
      }

      .mac.safari.b-hide.glass-bgs .frame.chart {
            overflow: hidden;
      }

      .mac.safari.b-hide.glass-bgs .frame.chart .vdo-frame {
            width: 140%;
            height: 140%;
            top: -30% !important;
            opacity: 0.5;
      }
}

@media (max-width: 419px) {
      .echarts-for-react {
            transform: scale(0.85);
      }

      .how-to-buy-wrape .head-section {
            padding: 0 0 15px;
      }

      .how-to-buy-wrape .right-section h3 {
            font-size: 22px;
      }

      .faq-wrape .MuiAccordionSummary-content {
            margin: 5px 0;
      }

}

@media (max-width: 360px) {
      .presale-wrape h4 {
            font-size: 1.5rem;
      }

      .presale-wrape .text-wrape h6 {
            font-size: 13px;
      }

      .presale-wrape .text-wrape .icon,
      .presale-wrape .text-wrape .icon svg {
            width: 22px;
            height: 22px;
      }

      .presale-wrape .gray-pad {
            padding: 18px 1rem;
      }

      .presale-wrape .gray-pad .inner-pad {
            flex-wrap: wrap;
            gap: 10px;
      }

      .presale-wrape .gray-pad .input-wrape {
            width: 100%;
      }

      .countdown-wrapper .time-section small {
            font-size: 13px;
      }

      .option-2 .hero-text-area h1::after {
            width: 100%;
            height: 94px;
            left: 5px;
            top: -8px;
      }

      .option-2 .hero-text-area h1,
      .option-2 .hero-text-area h1 span {
            line-height: 1.35;
            font-size: 30px;
      }

      .presale-wrape .footer-action .submit-btn {
            padding: 5px 25px 5px 5px;
            gap: 5px;
            font-size: 14px;
      }

      .presale-wrape .footer-action .wallet-connetct .icon {
            width: 42px;
            height: 42px;
      }

      .header-right-main {
            grid-column-gap: 5px;
            grid-row-gap: 5px;
      }

      .glass-bgs .countdown-wrapper {
            padding: 1rem;
      }

      .modal-wrape .modal-pad {
            padding: 20px;
      }

      .modal-wrape .modal-pad h2,
      .modal-wrape .modal-pad h2 span.frame {
            font-size: 22px;
            line-height: 1.25;
      }

      .modal-wrape .modal-pad p {
            font-size: 14px;
      }

      .b-hide.glass-bgs .presale-wrape {
            padding: 1rem;
      }

      .header-logo-box.symbol-logo {
            width: 42px;
            height: 42px;
      }

      .header-cust-col .mobile-menu-btn {
            min-width: 36px;
            padding: 6px 5px;
      }

      .view-detail.modal-wrape .modal-pad {
            padding: 0;
      }

      .modal-pad .cus-modal-li p.li-list-title {
            width: 110px;
      }
}

@media (max-width: 322px) {
      .b-hide.glass-bgs .chart-wrape .head-section {
            padding: 5px 0 0px;
      }

      .chart-wrape h4 {
            font-size: 19px;
      }

      .chart-wrape h4:after {
            width: 103px;
            height: 28px;
      }

      .b-hide.glass-bgs .roadmap-wrape .container {
            max-width: calc(100% - 30px);
      }

      .b-hide.glass-bgs .roadmap-wrape .swipper-main-wrape {
            padding: 0 15px;
      }

      .roadmap-wrape .controls {
            right: 40px;
            top: -19px;
            position: relative;
      }

      .roadmap-wrape .controls .swiper-button-prev,
      .roadmap-wrape .controls .swiper-button-next {
            width: 26px;
            height: 26px;
            padding: 6px;
      }

      .b-hide.glass-bgs .roadmap-wrape .roadmap-card-block.is-full {
            padding: 1.25rem 1rem;
      }

      .how-to-buy-wrape h4 {
            font-size: 23px;
      }

      .how-to-buy-wrape h4 span {
            font-size: 23px;
      }

      .how-to-buy-wrape h4:after {
            width: 221px;
            height: 36px;
      }

      .how-to-buy-wrape .head-section {
            padding: 0 0 15px;
      }

      .how-to-buy-wrape .right-section h3 {
            font-size: 18px;
            margin-bottom: 20px;
      }

      .how-to-buy-wrape .navlink b {
            font-size: 16px;
      }

      .how-to-buy-wrape .navlink span {
            font-size: 11px;
      }

      .b-hide.glass-bgs .how-to-buy-wrape .right-section p,
      .how-to-buy-wrape .right-section li {
            margin-bottom: 20px;
      }

      .how-to-buy-wrape .right-section ul,
      .how-to-buy-wrape .right-section li {
            font-size: 14px;
      }

      .b-hide.glass-bgs .faq-wrape .title-wrape h4 {
            font-size: 22px;
      }

      .faq-wrape .title-wrape h4:after {
            width: 120px;
            height: 34px;
      }

      .faq-wrape .MuiAccordionSummary-content {
            margin: 5px 0;
      }

      .faq-wrape .faq-section .head-label {
            font-size: 14px;
      }

      .footer-up h2 {
            font-size: 24px;
      }

      .footer-up h2::after {
            width: 222px;
            height: 66px;
      }

      .footer-up .store-wrape .border-btn {
            margin-left: 0;
      }

      .footer-up .store-wrape p {
            text-indent: 13px;
      }

      .footer-up .store-wrape p:after {
            left: 0;
      }

      .footer-up {
            width: 220px;
      }

      .footer-wrape ul {
            width: 100%;
      }

      .b-hide.glass-bgs .frame.chart .vdo-frame {
            width: 130%;
            height: 130%;
            top: -10%;
      }
}



/* height wise query start */
@media (min-height: 700px) {

      .mac.safari.b-hide.glass-bgs .frame.faq,
      .mac.safari.b-hide.glass-bgs .frame.chart {
            overflow: hidden;
      }

      .mac.safari.b-hide.glass-bgs .frame.faq .vdo-frame,
      .mac.safari.b-hide.glass-bgs .frame.chart .vdo-frame {
            top: -180px;
      }

      .mac.safari.b-hide.glass-bgs .container {
            max-width: 90%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape {
            padding: 2rem;
            max-width: inherit;
            position: relative;
            background: #ffffff;
            border-radius: 33px;
            width: 36%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape h4 {
            font-family: 'violetsans', sans-serif;
            font-weight: 400;
            font-size: 2rem;
            text-transform: uppercase;
            padding-bottom: 20px;
            position: relative;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            width: 100%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .text-wrape {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 0 10px;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .gray-pad p {
            font-size: 17px;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .gray-pad {
            background: #f6f6f6;
            border-radius: 16px;
            padding: 18px 24px;
            margin-bottom: 20px;
      }

      .mac.safari.b-hide.glass-bgs .input-pad input {
            border-radius: 6px;
            width: 100%;
            padding: 16.5px 14px;
            background-color: transparent;
            font-size: 17px;
            line-height: 20px;
            font-family: 'Inter', sans-serif;
            height: 57px;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency {
            background: var(--blue);
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency svg {
            height: 24px;
      }

      .mac.safari.b-hide.glass-bgs .progress-wrape {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            margin: 20px 0 10px;
      }

      .mac.safari.b-hide.glass-bgs .progress-wrape .progress-pad {
            width: 100%;
            display: flex;
            padding: 15px 0;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape p,
      .mac.safari.b-hide.glass-bgs .presale-wrape p b {
            font-size: 17px;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .footer-action .submit-btn {
            background: var(--black);
            border-radius: 30px;
            color: var(--white);
            text-transform: uppercase;
            font-weight: 500;
            padding: 5px 30px 5px 5px;
            font-size: 17px;
            gap: 10px;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape .footer-action .wallet-connetct .icon {
            width: 48px;
            height: 48px;
            background: rgba(255, 255, 255, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 8px;
      }

      .mac.safari .presale-wrape .text-wrape .icon,
      .mac.safari .presale-wrape .text-wrape .icon svg {
            width: 30px;
            height: 30px;
      }

      .mac.safari .presale-wrape .text-wrape h6 {
            font-size: 18px;
      }

      .mac.safari .hero-text-area {
            max-width: inherit;
            position: relative;
            width: 30%;
            z-index: 9;
      }

      .mac.safari .hero-text-area h1,
      .mac.safari .hero-text-area h1 span {
            font-size: 40px;
            width: 300px;
      }

      .mac.safari .hero-text-area p {
            font-size: 18px;
      }

      .mac.safari .countdown-wrapper {
            display: flex;
            border: 1px solid #efefef;
            border-radius: 30px;
            padding: 30px;
            max-width: inherit;
            flex-wrap: wrap;
            position: relative;
            background: rgba(255, 255, 255, 1);
            width: 33%;
            background: rgba(255, 255, 255, 0.25);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            border: 1px solid rgba(255, 255, 255, 1);
      }

      .mac.safari.b-hide.glass-bgs div.MuiSelect-select {
            min-height: 57px !important;
            padding: 12.5px 32px 12.5px 14px;
      }

      .mac.safari li.MuiMenuItem-root,
      .mac.safari div.MuiSelect-select {
            font-size: 17px;
      }

      .mac.safari .MuiSelect-select .dd-icon {
            width: 24px;
            height: 24px;
      }

      .mac.safari .countdown-wrapper .box-head-text {
            font-size: 28px;
      }

      .mac.safari .countdown-wrapper p {
            font-size: 17px;
      }

      .mac.safari .countdown-pad .size-medium .flip-countdown-piece .flip-countdown-title {
            font-size: 17px !important;
      }

      .mac.safari.b-hide.glass-bgs .container .container {
            max-width: 100%;
      }

      .mac.safari .table-wrape .MuiTableCell-head {
            font-size: 17px;
      }

      .mac.safari .MuiTableCell-root {
            font-size: 17px;
      }

      .mac.safari .table-wrape .table-icon {
            width: 48px;
            height: 48px;
            padding: 12px;
      }

      .mac.safari .table-wrape .table-icon svg {
            width: 24px;
            height: 24px;
      }

      .mac.safari .roadmap-wrape .bullet-text {
            font-size: 18px;
            line-height: 1.5;
      }

      .mac.safari.b-hide.glass-bgs .semi-parallax-wrape .roadmap-wrape .mySwiper {
            max-height: 450px;
      }

      .mac.safari .roadmap-bullet {
            grid-column-gap: 0.75rem;
            grid-row-gap: 0.75rem;
            margin-bottom: 1.85rem;
            display: flex;
      }

      .mac.safari .roadmap-wrape .bullet-icon svg {
            width: 20px;
            height: 20px;
            margin-top: 5px;
      }

      .mac.safari .roadmap-wrape .roadmap-title {
            font-size: 21px;
      }

      .mac.safari .roadmap-wrape .title-wrape p {
            font-size: 13px;
            padding-left: 13px;
            margin-bottom: 10px;
      }

      .mac.safari .roadmap-wrape .title-wrape p::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.safari .roadmap-wrape .title-wrape h4 {
            font-size: 30px;
      }

      .mac.safari .roadmap-wrape .title-wrape h4:after {
            width: 159px;
            height: 43px;
      }

      .mac.safari.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-prev,
      .mac.safari.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-next {
            width: 48px;
            height: 48px;
      }

      .mac.safari .modal-wrape .modal-pad {
            max-width: 650px;
      }

      .mac.safari .modal-wrape .modal-pad h2,
      .mac.safari .modal-wrape .modal-pad h2 span.frame {
            font-size: 44px;
      }

      .mac.safari .modal-wrape .modal-pad h2 span.frame::after {
            width: 360px;
            height: 56px;
            top: -10px;
      }

      .mac.safari .modal-wrape .modal-pad p {
            font-size: 18px;
      }

      .mac.safari .modal-wrape .modal-pad a.modal-close {
            width: 36px;
            height: 36px;
            padding: 10px 11px 0;
      }

      .mac.safari .how-to-buy-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
      }

      .mac.safari .how-to-buy-wrape p.head-label::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.safari .how-to-buy-wrape h4,
      .mac.safari .how-to-buy-wrape h4 span {
            font-size: 30px;
      }

      .mac.safari .how-to-buy-wrape .left-section {
            width: 330px;
            padding-right: 10px;
      }

      .mac.safari .how-to-buy-wrape .right-section {
            width: calc(100% - 330px);
      }

      .mac.safari .how-to-buy-wrape .title-wrape p {
            font-size: 16px;
            line-height: 1.5;
      }

      .mac.safari .how-to-buy-wrape h4:after {
            width: 286px;
            height: 47px;
      }

      .mac.safari .how-to-buy-wrape .navlink b {
            font-size: 24px;
      }

      .mac.safari .how-to-buy-wrape .navlink span {
            font-size: 15px;
      }

      .mac.safari .how-to-buy-wrape .right-section h3 {
            font-size: 28px;
      }

      .mac.safari .how-to-buy-wrape .right-section li b {
            font-size: 18px;
      }

      .mac.safari .how-to-buy-wrape .right-section ul,
      .mac.safari .how-to-buy-wrape .right-section li {
            font-size: 18px;
            line-height: 1.5;
      }

      .mac.safari .faq-wrape .title-wrape h4 {
            font-size: 34px;
      }

      .mac.safari .faq-wrape .title-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
      }

      .mac.safari .faq-wrape .title-wrape p.head-label::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.safari .faq-wrape .title-wrape p {
            font-size: 16px;
      }

      .mac.safari .faq-wrape .faq-section .number {
            font-size: 18px;
      }

      .mac.safari .faq-wrape .faq-section .head-label {
            font-size: 18px;
      }

      .mac.safari .faq-wrape .accr-pad .MuiAccordionDetails-root p {
            font-size: 15px;
      }

      .mac.safari .faq-wrape .title-wrape h4:after {
            width: 180px;
            height: 58px;
            bottom: -8px;
      }

      .mac.safari .chart-wrape {
            height: calc(100% - 390px);
      }

      .mac.safari .chart-wrape .chart-section {
            height: calc(100% - 130px);
      }

      .mac.safari .chart-wrape .chart-section .App {
            height: 100%;
      }

      .mac.safari .echarts-for-react {
            min-height: 100%;
      }

      .mac.safari .chart-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
            gap: 8px;
            display: flex;
            justify-content: center;
      }

      .mac.safari .chart-wrape p.head-label::before {
            position: relative;
            left: inherit;
            width: 8px;
            height: 8px;
            top: 6px;
      }

      .mac.safari .chart-wrape h4 {
            font-size: 34px;
      }

      .mac.safari .chart-wrape h4:after {
            width: 180px;
            height: 58px;
            bottom: -10px;
      }

      .mac.safari .chart-wrape .title-wrape p {
            font-size: 16px;
      }

      .mac.safari.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road .container {
            width: calc(100% - 70px);
            max-width: 100%;
      }





      .mac.chrome.b-hide.glass-bgs .container {
            max-width: 90%;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape {
            padding: 2rem;
            max-width: inherit;
            position: relative;
            background: #ffffff;
            border-radius: 33px;
            width: 36%;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape h4 {
            font-family: 'violetsans', sans-serif;
            font-weight: 400;
            font-size: 2rem;
            text-transform: uppercase;
            padding-bottom: 20px;
            position: relative;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            width: 100%;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .text-wrape {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 0 10px;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .gray-pad p {
            font-size: 17px;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .gray-pad {
            background: #f6f6f6;
            border-radius: 16px;
            padding: 18px 24px;
            margin-bottom: 20px;
      }

      .mac.chrome.b-hide.glass-bgs .input-pad input {
            border-radius: 6px;
            width: 100%;
            padding: 16.5px 14px;
            background-color: transparent;
            font-size: 17px;
            line-height: 20px;
            font-family: 'Inter', sans-serif;
            height: 57px;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency {
            background: var(--blue);
            width: 42px;
            height: 42px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency svg {
            height: 24px;
      }

      .mac.chrome.b-hide.glass-bgs .progress-wrape {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 15px 0;
            border-top: 1px solid #f0f0f0;
            border-bottom: 1px solid #f0f0f0;
            margin: 20px 0 10px;
      }

      .mac.chrome.b-hide.glass-bgs .progress-wrape .progress-pad {
            width: 100%;
            display: flex;
            padding: 15px 0;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape p,
      .mac.chrome.b-hide.glass-bgs .presale-wrape p b {
            font-size: 17px;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .footer-action .submit-btn {
            background: var(--black);
            border-radius: 30px;
            color: var(--white);
            text-transform: uppercase;
            font-weight: 500;
            padding: 5px 30px 5px 5px;
            font-size: 17px;
            gap: 10px;
      }

      .mac.chrome.b-hide.glass-bgs .presale-wrape .footer-action .wallet-connetct .icon {
            width: 48px;
            height: 48px;
            background: rgba(255, 255, 255, 1);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 8px;
      }

      .mac.chrome .presale-wrape .text-wrape .icon,
      .mac.chrome .presale-wrape .text-wrape .icon svg {
            width: 30px;
            height: 30px;
      }

      .mac.chrome .presale-wrape .text-wrape h6 {
            font-size: 18px;
      }

      .mac.chrome .hero-text-area {
            max-width: inherit;
            position: relative;
            width: 30%;
            z-index: 9;
      }

      .mac.chrome .hero-text-area h1,
      .mac.chrome .hero-text-area h1 span {
            font-size: 40px;
            width: 300px;
      }

      .mac.chrome .hero-text-area p {
            font-size: 18px;
      }

      .mac.chrome .countdown-wrapper {
            display: flex;
            border: 1px solid #efefef;
            border-radius: 30px;
            padding: 30px;
            max-width: inherit;
            flex-wrap: wrap;
            position: relative;
            background: rgba(255, 255, 255, 1);
            width: 33%;
            background: rgba(255, 255, 255, 0.25);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
            backdrop-filter: blur(3px);
            -webkit-backdrop-filter: blur(3px);
            border: 1px solid rgba(255, 255, 255, 1);
      }

      .mac.chrome.b-hide.glass-bgs div.MuiSelect-select {
            min-height: 57px !important;
            padding: 12.5px 32px 12.5px 14px;
      }

      .mac.chrome li.MuiMenuItem-root,
      .mac.chrome div.MuiSelect-select {
            font-size: 17px;
      }

      .mac.chrome .MuiSelect-select .dd-icon {
            width: 24px;
            height: 24px;
      }

      .mac.chrome .countdown-wrapper .box-head-text {
            font-size: 28px;
      }

      .mac.chrome .countdown-wrapper p {
            font-size: 17px;
      }

      .mac.chrome .countdown-pad .size-medium .flip-countdown-piece .flip-countdown-title {
            font-size: 17px !important;
      }

      .mac.chrome.b-hide.glass-bgs .container .container {
            max-width: 100%;
      }

      .mac.chrome .table-wrape .MuiTableCell-head {
            font-size: 17px;
      }

      .mac.chrome .MuiTableCell-root {
            font-size: 17px;
      }

      .mac.chrome .table-wrape .table-icon {
            width: 48px;
            height: 48px;
            padding: 12px;
      }

      .mac.chrome .table-wrape .table-icon svg {
            width: 24px;
            height: 24px;
      }

      .mac.chrome .roadmap-wrape .bullet-text {
            font-size: 18px;
            line-height: 1.5;
      }

      .mac.chrome.b-hide.glass-bgs .semi-parallax-wrape .roadmap-wrape .mySwiper {
            max-height: 450px;
      }

      .mac.chrome .roadmap-bullet {
            grid-column-gap: 0.75rem;
            grid-row-gap: 0.75rem;
            margin-bottom: 1.85rem;
            display: flex;
      }

      .mac.chrome .roadmap-wrape .bullet-icon svg {
            width: 20px;
            height: 20px;
            margin-top: 5px;
      }

      .mac.chrome .roadmap-wrape .roadmap-title {
            font-size: 21px;
      }

      .mac.chrome .roadmap-wrape .title-wrape p {
            font-size: 13px;
            padding-left: 13px;
            margin-bottom: 10px;
      }

      .mac.chrome .roadmap-wrape .title-wrape p::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.chrome .roadmap-wrape .title-wrape h4 {
            font-size: 30px;
      }

      .mac.chrome .roadmap-wrape .title-wrape h4:after {
            width: 159px;
            height: 43px;
      }

      .mac.chrome.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-prev,
      .mac.chrome.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-next {
            width: 48px;
            height: 48px;
      }

      .mac.chrome .modal-wrape .modal-pad {
            max-width: 650px;
      }

      .mac.chrome .modal-wrape .modal-pad h2,
      .mac.chrome .modal-wrape .modal-pad h2 span.frame {
            font-size: 44px;
      }

      .mac.chrome .modal-wrape .modal-pad h2 span.frame::after {
            width: 360px;
            height: 56px;
            top: -10px;
      }

      .mac.chrome .modal-wrape .modal-pad p {
            font-size: 18px;
      }

      .mac.chrome .modal-wrape .modal-pad a.modal-close {
            width: 36px;
            height: 36px;
            padding: 10px 11px 0;
      }

      .mac.chrome .how-to-buy-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
      }

      .mac.chrome .how-to-buy-wrape p.head-label::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.chrome .how-to-buy-wrape h4,
      .mac.chrome .how-to-buy-wrape h4 span {
            font-size: 30px;
      }

      .mac.chrome .how-to-buy-wrape .left-section {
            width: 330px;
            padding-right: 10px;
      }

      .mac.chrome .how-to-buy-wrape .right-section {
            width: calc(100% - 330px);
      }

      .mac.chrome .how-to-buy-wrape .title-wrape p {
            font-size: 16px;
            line-height: 1.5;
      }

      .mac.chrome .how-to-buy-wrape h4:after {
            width: 286px;
            height: 47px;
      }

      .mac.chrome .how-to-buy-wrape .navlink b {
            font-size: 24px;
      }

      .mac.chrome .how-to-buy-wrape .navlink span {
            font-size: 15px;
      }

      .mac.chrome .how-to-buy-wrape .right-section h3 {
            font-size: 28px;
      }

      .mac.chrome .how-to-buy-wrape .right-section li b {
            font-size: 18px;
      }

      .mac.chrome .how-to-buy-wrape .right-section ul,
      .mac.chrome .how-to-buy-wrape .right-section li {
            font-size: 18px;
            line-height: 1.5;
      }

      .mac.chrome .faq-wrape .title-wrape h4 {
            font-size: 34px;
      }

      .mac.chrome .faq-wrape .title-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
      }

      .mac.chrome .faq-wrape .title-wrape p.head-label::before {
            width: 8px;
            height: 8px;
            top: 4px;
      }

      .mac.chrome .faq-wrape .title-wrape p {
            font-size: 16px;
      }

      .mac.chrome .faq-wrape .faq-section .number {
            font-size: 18px;
      }

      .mac.chrome .faq-wrape .faq-section .head-label {
            font-size: 18px;
      }

      .mac.chrome .faq-wrape .accr-pad .MuiAccordionDetails-root p {
            font-size: 15px;
      }

      .mac.chrome .faq-wrape .title-wrape h4:after {
            width: 180px;
            height: 58px;
            bottom: -8px;
      }

      .mac.chrome .chart-wrape {
            height: calc(100% - 390px);
      }

      .mac.chrome .chart-wrape .chart-section {
            height: calc(100% - 130px);
      }

      .mac.chrome .chart-wrape .chart-section .App {
            height: 100%;
      }

      .mac.chrome .echarts-for-react {
            min-height: 100%;
      }

      .mac.chrome .chart-wrape p.head-label {
            font-size: 13px;
            padding-left: 13px;
            gap: 8px;
            display: flex;
            justify-content: center;
      }

      .mac.chrome .chart-wrape p.head-label::before {
            position: relative;
            left: inherit;
            width: 8px;
            height: 8px;
            top: 6px;
      }

      .mac.chrome .chart-wrape h4 {
            font-size: 34px;
      }

      .mac.chrome .chart-wrape h4:after {
            width: 180px;
            height: 58px;
            bottom: -10px;
      }

      .mac.chrome .chart-wrape .title-wrape p {
            font-size: 16px;
      }

      .mac.chrome.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road .container {
            width: calc(100% - 70px);
            max-width: 100%;
      }
}

@media (min-height: 700px) and (max-width: 767px) {
      .mac.safari.b-hide.glass-bgs .container {
            max-width: 100%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape {
            width: 100%;
      }

      .mac.safari.b-hide.glass-bgs .one .countdown-wrapper {
            width: 100%;
            max-width: 100%;
      }

      /* .mac.chrome .chart-wrape .chart-section .App {
            height: calc(100% - 390px);
      } */
      .mac.safari .how-to-buy-wrape .left-section {
            width: 100%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape,
      .mac.safari .countdown-wrapper,
      .mac.safari .hero-text-area {
            width: 100%;
      }

      .mac.safari.b-hide.glass-bgs .presale-wrape {
            width: 100% !important;
      }
}

/* height wise query end */
@media (min-height: 700px) {
      .mac.safari.b-hide.glass-bgs .presale-wrape {
            width: 60%;
      }

      .mac.safari .hero-text-area {
            width: 35%;
      }

      .mac.safari .countdown-wrapper {
            width: 100%;
      }
}