/* @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2)  and (min-resolution: 192dpi) { 
    
} */
/* @media screen and (-webkit-min-device-pixel-ratio:0) { 
    .faq-wrape .title-wrape h4:after{
        right: 0;
        left: unset;
    }
    body{
        background-color: red;
    }
} */
@media (max-width: 1200px) {
    .new-hero-sec .cus-height {
        height: auto;

    }
}

@media screen and (min-width: 1023px) and (max-width: 1700px) and (-webkit-min-device-pixel-ratio:0) {

    .faq-wrape .title-wrape h4:after {
        right: -3px;
        left: unset;
    }

    .pre-seed-sec h4:after {
        bottom: -3px;
    }

    .faq-wrape .title-wrape h4:after {
        width: 142px;
    }
}

@media (max-width: 1919px) {
    .box-frame {
        min-height: 250px;
    }
}

@media (max-width: 1367px) {
    .countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card {
        gap: 4px;
    }

    .hero-main-rapper .countdown-wrapper {
        max-width: inherit;
        padding: 20px;
    }

    .new-chart-sec {
        height: 2000px;
    }

    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
        width: 30px !important;
    }

    .countdown-pad .size-medium.flip-countdown {
        gap: 8px;
    }

    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__bottom,
    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__top {
        width: 30px !important;
    }

    .chart-detail-col,
    .chart-detail-section {
        justify-content: start;
    }

    .pre-seed-sec {
        padding: 1rem 1.5rem;
    }

    .pre-seed-sec .gray-pad {
        margin-bottom: 10px;
        padding: 8px 15px;
    }

    .pre-seed-sec p.base-price {
        margin-top: 10px;
        padding-top: 10px;
    }

    .progress-wrape {
        padding: 15px 0;
        margin: 15px 0;
    }
}

@media (max-width: 1249px) {}

@media (max-width: 1023px) {
    .hero-sec-col {
        margin-bottom: 30px;
    }

    .new-hero-sec .btn-wrape {
        padding-bottom: 0;
    }

    .py-80 {
        padding: 0px 0;
    }

    .countdown-pad .size-medium.flip-countdown {
        gap: 20px;
    }
}

@media (max-width: 767px) {

    .how-to-buy-wrape .d-flex-buy {
        flex-direction: column;
    }

    .how-to-buy-wrape .right-section {
        width: 100%;
    }

    /* .py-80 {
        padding: 0px 0;
    } */
    .table-wrape {
        padding: 0 10px;
    }

    .new-roadmap-sec .roadmap-wrape {
        padding: 0 10px;
    }

    .new-howToBuy-sec .how-to-buy-wrape {
        padding: 0px 10px;
    }

    .px-30 {
        padding: 0 10px;
    }

    .new-footer-sec .footer-wrape {
        width: auto;
        height: auto;
        padding-bottom: 20px;
    }

    .inner-section {
        grid-template-columns: repeat(1, 1fr);
        gap: 0;
    }

    .how-to-buy-wrape h4:after {
        right: -3px;
        left: unset;
    }

    .new-faq-sec {
        margin-top: -30px;
    }

    .bottom-link-wrape {
        align-items: flex-start;
    }

    .new-hero-sec .cus-height {
        height: auto;
    }

    .countdown-pad .size-medium.flip-countdown {
        gap: 5px;
    }

    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
        width: 25px !important;
    }

    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__bottom,
    .countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__top {
        width: 25px !important;
    }

    .clock__display {
        height: 60px;
        width: 60px;
    }

    .clock__amount {
        font-size: 1rem;
    }

    .clock__unit {
        font-size: 0.60rem;
    }
    .progress-modal-main .progress-modal-inner {
        min-width: 340px;
        max-width: 340px;
    }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {}