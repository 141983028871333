/* start header css */
.header-main {
      background-color: #FFFFFF;
      padding: 12px 0px 12px;
      position: sticky;
      z-index: 12;
      transition: all 0.25s ease-in-out 0.25s;
      top: 0;
}

.header-main.is-sticky {
      transition: all 0.25s ease-in-out 0.25s;
      padding: 7px 0px 7px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.10);
}

.header-logo-box {
      display: flex;
      width: 102px;
}

.header-logo-box svg {
      width: 100%;
      height: auto;
}

.header-logo-box.symbol-logo,
.sidebar-brand-wrape {
      display: none;
}

.header-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
}

.header-logo-box .header-logo {
      height: 58px;
      max-width: 158px;
      object-fit: contain;
      width: 100%;
}

.header-nav-main .header-nav-ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style-type: none;
      padding: 0px;
      margin: 0px;
      gap: 25px;
}

.header-nav-main .header-nav-li {
      padding: 0px;
      gap: 20px;
}

.header-nav-main .header-nav-li:last-child {
      margin-right: 0px;
}

.header-nav-main .header-nav-link {
      font-size: 14px;
      line-height: 18px;
      color: rgba(0, 0, 0, .6);
      text-decoration: none;
      white-space: nowrap;
      text-transform: uppercase;
}

.header-nav-main .header-nav-link:hover,
.header-nav-main .header-nav-link.active {
      color: #000000;
}

.header-nav-main .header-nav-link:after {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 4px;
      height: 2px;
      background: #38FF8E;
      border-radius: 2px;
      left: 50%;
      margin-left: -2px;
      transition: all 0.15s linear 0.15s;
      opacity: 0;
}

.header-nav-main .header-nav-link:hover:after,
.header-nav-main .header-nav-link.active:after {
      width: 16px;
      height: 2px;
      margin-left: -8px;
      transition: all 0.15s linear 0.15s;
      opacity: 1;
}

.header-right-main {
      display: flex;
      align-items: center;
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
}

.header-right-main a {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background: #38FF8E;
      display: flex;
      justify-content: center;
      align-items: center;
}

.header-right-main a svg {
      width: 17px;
      height: 17px;
}

.header-right-main .header-login-link {
      color: #1f1f1f;
      font-size: 14px;
      line-height: 18px;
      font-family: 'Inter-regular', sans-serif;
      text-decoration: none;
      display: flex;
}

.header-right-main .header-login-link:hover {
      color: #2B5AF3;
}

.header-right-main .auth-wrape {
      display: flex;
      align-items: center;
      margin-left: 25px;
}

.header-right-main .auth-wrape img {
      margin-right: 6px;
}

.primary-btn-main .header-create-account-link {
      min-width: 174px;
      margin-right: 30px;
}

.header-search-box {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 19px;
      border: 0.67px solid #DBDBDB;
      background: #f2f2f2;
      padding: 8px;
}

.header-search-box fieldset {
      display: none;
}

.header-search-box input {
      padding: 0 0 0 10px;
      background: none;
      border: 0;
}

.header-search-icon {
      width: 16px;
      height: 16px;
      object-fit: contain;
}

.mobile-menu-icon {
      display: block;
}

button.btn {
      border-radius: 30px;
      text-transform: uppercase;
      font-weight: 500;
      padding: 15px 24px;
      font-size: 14px;
      white-space: nowrap;
      border: 1px solid transparent;
}

button.btn-primary {
      color: var(--white);
      background: var(--blue);
}

button.btn-primary:hover {
      border-color: var(--blue);
      color: var(--blue);
}

button.btn-secondary {
      color: var(--black);
      background: var(--green);
}

button.btn-secondary:hover {
      border-color: var(--green);
      color: var(--black);
}

.footer-wrape {
      background: var(--black);
      color: var(--white);
      padding: 60px 0;
      position: sticky;
      top: 0;
      z-index: 9;
}

.footer-wrape .social-media-wrape a {
      opacity: 1;
}

.footer-up {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: .0625rem solid rgba(255, 255, 255, .15);
      padding-bottom: 60px;
      margin-bottom: 60px;
}

.footer-up .store-wrape p {
      color: var(--white);
      position: relative;
      font-size: 11px;
      text-transform: uppercase;
      text-indent: 20px;
      margin-bottom: 10px;
}

.footer-up .store-wrape p:after {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      display: inline-block;
      background: var(--green);
      border-radius: 50%;
      left: 10px;
      top: 3px;
}

.footer-up .store-wrape .border-btn {
      border: 1px solid var(--white);
      border-radius: 30px;
      color: var(--white);
      padding: 4px 15px 4px 6px;
      font-size: 11px;
      text-transform: uppercase;
      margin-left: 10px;
}

.footer-up .store-wrape .border-btn .icon {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: var(--green);
      padding: 6px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
}

.footer-up .store-wrape .border-btn .icon svg {
      width: 16px;
      height: 16px;
}

.footer-up h2 {
      position: relative;
      color: var(--white);
      font-size: 32px;
      font-family: var(--headfont);
      width: 290px;
      line-height: 1.3;
}

.footer-up h2::after {
      content: "";
      position: absolute;
      bottom: 36px;
      left: -5px;
      display: inline-block;
      width: 290px;
      height: 85px;
      background: url(../../../assets/images/svg/frame-footer.svg) 0 0 no-repeat;
}

.footer-up .store-wrape {
      /* width: calc(100% - 290px); */
}

.footer-up .store-wrape button {
      margin-bottom: 10px;
}

.footer-wrape .footer-down {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
}

.footer-wrape .footer-menus {
      width: 60%;
      display: flex;
}

.footer-wrape .footer-brand {
      width: 40%;
}

.footer-wrape .footer-brand a {
      opacity: 1;
}

.footer-wrape .footer-brand a.brand-logo {
      width: 130px;
      display: flex;
}

.footer-wrape h6,
.footer-wrape a {
      color: var(--white);
      text-transform: uppercase;
}

.footer-wrape h6 {
      font-family: var(--headfont);
      font-size: 13px;
}

.footer-wrape ul {
      width: 33.33%;
}

.footer-wrape li {
      margin-bottom: 20px;
      list-style: none inside;
}

.footer-wrape a {
      font-size: 12px;
      font-family: var(--headfont);
      opacity: 0.6;
      font-weight: 400;
}

.footer-wrape b {
      font-size: 12px;
      font-family: var(--headfont);
      opacity: 1;
      font-weight: 400;
      color: var(--white);
}

.footer-copyright {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.bottom-link-wrape {
      display: flex;
      gap: 10px;
      align-items: center;
}

.bottom-link-wrape p,
.bottom-link-wrape a {
      color: var(--white);
      opacity: 0.65;
      font-size: 10px;
}

.bottom-link-wrape a:hover {
      opacity: 1;
}

/* end header css */

.tall .header-nav-main .header-nav-link {
      font-size: 18px;
      line-height: 22px;
}

.tall button.btn {
      font-size: 18px;
}

.tall .header-right-main a {
      width: 48px;
      height: 48px;
}

.tall .header-right-main a svg {
      width: 24px;
      height: 24px;
}

.tall .header-logo-box {
      width: 136px;
}

.tall .footer-up h2 {
      width: 380px;
      font-size: 44px;
}

.tall .footer-up h2::after {
      bottom: -20px;
      width: 400px;
      height: 135px;
}

.tall .footer-up .store-wrape p {
      font-size: 17px;
      padding-left: 5px;
}

.tall .footer-up .store-wrape p:after {
      width: 8px;
      height: 8px;
      top: 4px;
}

.tall .footer-up .store-wrape .border-btn {
      font-size: 17px;
}

.tall .footer-up .store-wrape .border-btn .icon {
      width: 42px;
      height: 42px;
}

.tall .footer-up .store-wrape .border-btn .icon svg {
      width: 22px;
      height: 22px;
}

.tall .footer-wrape h6 {
      font-size: 17px;
}

.tall .footer-wrape a,
.tall .footer-wrape b {
      font-size: 17px;
}

.tall .bottom-link-wrape p,
.tall .bottom-link-wrape a {
      font-size: 14px;
}

.tall .footer-wrape .footer-brand a.brand-logo {
      width: 160px;
}



/* @media (min-height: 700px) {
      .mac.safari .header-nav-main .header-nav-link {
            font-size: 18px;
            line-height: 22px;
      }
      .mac.safari button.btn {
            font-size: 18px;
      }
      .mac.safari .header-right-main a {
            width: 48px;
            height: 48px;
      }
      .mac.safari .header-right-main a svg {
            width: 24px;
            height: 24px;
      }
      .mac.safari .header-logo-box {
            width: 136px;
      }
      .mac.safari .footer-up h2 {
            width: 380px;
            font-size: 44px;
      }
      .mac.safari .footer-up h2::after {
            bottom: -20px;
            width: 400px;
            height: 135px;
      }
      .mac.safari .footer-up .store-wrape p {
            font-size: 17px;
            padding-left: 5px;
      }
      .mac.safari .footer-up .store-wrape p:after {
            width: 8px;
            height: 8px;
            top: 4px;
      }
      .mac.safari .footer-up .store-wrape .border-btn {
            font-size: 17px;
      }
      .mac.safari .footer-up .store-wrape .border-btn .icon {
            width: 42px;
            height: 42px;
      }
      .mac.safari .footer-up .store-wrape .border-btn .icon svg {
            width: 22px;
            height: 22px;
      }
      .mac.safari .footer-wrape h6 {
            font-size: 17px;
      }
      .mac.safari .footer-wrape a, .mac.safari .footer-wrape b {
            font-size: 17px;
      }
      .mac.safari .bottom-link-wrape p, .mac.safari .bottom-link-wrape a {
            font-size: 14px;
      }
      .mac.safari .footer-wrape .footer-brand a.brand-logo {
            width: 160px;
      }






      .mac.chrome .header-nav-main .header-nav-link {
            font-size: 18px;
            line-height: 22px;
      }
      .mac.chrome button.btn {
            font-size: 18px;
      }
      .mac.chrome .header-right-main a {
            width: 48px;
            height: 48px;
      }
      .mac.chrome .header-right-main a svg {
            width: 24px;
            height: 24px;
      }
      .mac.chrome .header-logo-box {
            width: 136px;
      }
      .mac.chrome .footer-up h2 {
            width: 380px;
            font-size: 44px;
      }
      .mac.chrome .footer-up h2::after {
            bottom: -20px;
            width: 400px;
            height: 135px;
      }
      .mac.chrome .footer-up .store-wrape p {
            font-size: 17px;
            padding-left: 5px;
      }
      .mac.chrome .footer-up .store-wrape p:after {
            width: 8px;
            height: 8px;
            top: 4px;
      }
      .mac.chrome .footer-up .store-wrape .border-btn {
            font-size: 17px;
      }
      .mac.chrome .footer-up .store-wrape .border-btn .icon {
            width: 42px;
            height: 42px;
      }
      .mac.chrome .footer-up .store-wrape .border-btn .icon svg {
            width: 22px;
            height: 22px;
      }
      .mac.chrome .footer-wrape h6 {
            font-size: 17px;
      }
      .mac.chrome .footer-wrape a, .mac.chrome .footer-wrape b {
            font-size: 17px;
      }
      .mac.chrome .bottom-link-wrape p, .mac.chrome .bottom-link-wrape a {
            font-size: 14px;
      }
      .mac.chrome .footer-wrape .footer-brand a.brand-logo {
            width: 160px;
      }
} */