.modal-head {
    padding: 15px 20px 10px;
    position: relative;
    display: flex;
    width: 100%;
    font-family: var(--headfont);
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--blue);
    border-bottom: 1px solid #efefef;
}

.modal-body {
    display: flex;
    width: 100%;
    padding: 20px 20px 0px;
}

.account-data-ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-bottom: 20px;
    width: 100%;
}

.account-data-li {
    display: flex;
    gap: 10px;
}

.account-li-lable {
    font-size: 13px !important;
    font-weight: 600;
    color: #000000;
    margin-bottom: 0px !important;
}

.account-li-data {
    font-size: 13px !important;
    color: #666666;
    font-weight: 400;
    margin-bottom: 0px !important;
}

.cus-modal-li {
    width: 100%;
    display: flex;
}

.modal-pad .modal-body .cus-modal-li p {
    font-size: 15px;
    color: #666666;
    font-weight: 400;
}

.modal-pad .cus-modal-li p.li-list-title {
    font-weight: 600;
    width: 160px;
    color: #000000;
}

.modal-footer {
    padding: 10px 15px 10px;
    display: flex;
    justify-content: flex-start;
}

.modal-footer .modal-btn-sec button {
    padding: 10px 25px;
    background: var(--blue);
    color: var(--white);
    font-family: var(--headfont);
    text-transform: uppercase;
    border: 15px;
}

.sticky-glass-bgs .table-wrape th,
.sticky-glass-bgs .table-wrape td {
    text-align: center;
}

.sticky-glass-bgs .table-wrape th:first-child,
.sticky-glass-bgs .table-wrape td:first-child {
    max-width: 30px;
}

.view-flex {
    display: grid;
    gap: 25px;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
}

.view-flex .view-content .view-content-btn {
    background-color: transparent;
    padding: 4px 7px;
    font-size: 14px;
    border-radius: 5px;
    text-transform: capitalize;
    border: 1px solid #e79654;
    color: #e79654;
}

.view-wrapper {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 10px;
    margin: 0px 10px 10px;
    background: #000;
    padding: 10px;
}

.view-content {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
}

.view-alert-box .MuiAlert-message {
    padding: 0px !important;
    width: 100%;
}