.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  color: #121212;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  color: #121212;
}

.modal-content {
  margin-top: 20px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.approval-steps {
  background-color: #89aed8;
  border-radius: 10px;
  padding: 10px;
}

.swap-button {
  background-color: #6200ea;
  color: white;
  margin-top: 10px;
}