:root {
    --blue: #2B5AF3;
    --green: #38FF8E;
    --black: #000000;
    --white: #ffffff;
    --headfont: 'violetsans';
    --bodyfont: 'Inter';
}

@font-face {
    font-family: 'violetsans';
    src: url(../fonts/VioletSans-Regular.ttf);
}

@font-face {
    font-family: 'Inter';
    src: url(../fonts/Inter-Regular.ttf);
}

.container {
    padding: 0 10px;
    width: 100%;
    margin: auto;
    max-width: 90%;
}

.header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-menu-sec .header-nav-ul {
    display: flex;
    align-items: center;
    gap: 25px;
}

.header-nav-main .header-nav-link.active,
.header-nav-main .header-nav-link:hover {
    color: #000;
}

.header-nav-main .header-nav-link {
    color: #0009;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
}

.sec-padding {
    padding: 20px 0;
}

.hero-text-area h1,
.hero-text-area h1 span {
    margin-bottom: 15px;
    color: var(--black);
    font-family: 'violetsans', sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    width: 250px;
    position: relative;
}

.hero-text-area p {
    color: #666666;
    line-height: 1.35;
    font-size: 16px;
}

.new-hero-sec .btn-wrape {
    display: flex;
    gap: 15px;
    padding: 20px 0;
}

.pre-seed-sec {
    padding: 1.5rem 1.5rem;
    background: #fff;
    border-radius: 33px;
    width: 100%;
}

.pre-seed-sec h4 {
    display: flex;
    font-family: violetsans, sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 0;
    position: relative;
    text-transform: uppercase;
    width: 100%;
}

.pre-seed-sec h4 .label-wrape {}

.pre-seed-sec h4 .label-wrape .open i {
    background: #18bf2e;
}

.pre-seed-sec h4 .label-wrape i {
    border-radius: 5px;
    display: inline-block;
    height: 10px;
    margin-right: 5px;
    width: 10px;
}

.pre-seed-sec h4:after {
    background: #2b5af3;
    background: var(--blue);
    bottom: -3px;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    transition: all .15s linear .15s;
    width: 16px;
}

.pre-seed-sec:hover h4:after {
    transition: all .15s linear .15s;
    width: 30px;
}

.main-bg {
    background-image: url(../images/png/glassbg.png);
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size: cover; */
}

.pre-seed-sec .text-wrape h6 {
    align-items: center;
    display: flex;
    gap: 10px;
}

.pre-seed-sec .text-wrape .icon,
.pre-seed-sec .text-wrape .icon svg {
    height: 24px;
    width: 24px;
}

.pre-seed-sec .text-wrape {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    width: 100%;
}

.pre-seed-sec .gray-pad {
    background: #f6f6f6;
    border-radius: 16px;
    margin-bottom: 20px;
    padding: 15px 15px;
}

.pre-seed-sec .gray-pad p {
    color: var(--black);
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
    margin-bottom: 6px;
}

.pre-seed-sec .gray-pad .inner-pad {
    display: flex;
    justify-content: space-between;
}

.pre-seed-sec .gray-pad .input-wrape {
    width: calc(50% - 10px);
}

.input-pad {
    background: var(--white);
    border: 1px solid #b4b4b4;
    border-radius: 16px;
    width: 100%;
}

.pre-seed-sec p.base-price {
    border-top: 1px solid #dadada;
    margin-bottom: 0;
    margin-top: 20px;
    padding-top: 15px;
    text-align: center;
}

.pre-seed-sec .gray-pad p {
    color: #000;
    color: var(--black);
    font-weight: 500;
    /* margin-bottom: 0px; */
    /* text-align: left; */
}

.pre-seed-sec p b {
    color: #2b5af3;
    color: var(--blue);
    font-weight: 600;
}

.pre-seed-sec .gray-pad .p-currency {
    background: var(--blue);
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pre-seed-sec .p-currency svg {
    height: 20px;
}

.pre-seed-sec .gray-pad h1 {
    align-items: center;
    display: flex;
    font-size: 30px;
    font-weight: 600;
    gap: 10px;
}

.progress-wrape p,
.progress-pad p {
    text-align: center;
}

.pre-seed-sec .footer-action {
    width: 100%;
}

.pre-seed-sec .footer-action .submit-btn {
    background: #000;
    background: var(--black);
    border-radius: 30px;
    color: #fff;
    color: var(--white);
    font-size: 15px;
    font-weight: 500;
    gap: 10px;
    padding: 5px 30px 5px 5px;
    text-transform: uppercase;
}

.pre-seed-sec .footer-action .wallet-connetct .icon {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    display: flex;
    height: 38px;
    justify-content: center;
    padding: 8px;
    width: 38px;
}

.countdown-wrapper {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background: #fff;
    background: #ffffff40;
    border: 1px solid #fff;
    border-radius: 30px;
    box-shadow: 0 8px 32px 0 #1f26871a;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    padding: 1.5rem;
    width: 100%;
}

.hero-main-rapper .countdown-wrapper {
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background: #fff;
    background: #ffffff40;
    border: 1px solid #fff;
    border-radius: 30px;
    box-shadow: 0 8px 32px 0 #1f26871a;
    display: flex;
    flex-wrap: wrap;
    max-width: inherit;
    padding: 30px;
}

.glass-bg {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(1px);
    border-bottom-width: 2px;
    border-color: #fff;
    box-shadow: 0 0 2px #0003;
    z-index: 9;
}

.hero-sec-col {
    display: flex;
    align-items: center;
    justify-content: center;
}

.py-80 {
    padding: 00px 0;
}

.glass-bg .input-pad input {
    height: 35px;
    padding: 11.5px 14px;
}

.glass-bg div.MuiSelect-select {
    min-height: 35px !important;
    max-height: 35px;
    padding: 12.5px 32px 12.5px 14px;
}

.box-frame {
    border: 1px solid #fff;
    box-shadow: 0 1px 3px #00000040;
    /* min-height: 500px; */
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: calc(100vh - 100px); */
    /* margin-bottom: 50px; */
}

.main-bg .box-frame.glass-bg {
    align-items: center;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(1px);
    background: #ffffff40;
    border: 1px solid #fff;
    border-radius: 30px;
    box-shadow: 0 0 2px #0003;
    /* min-height: 100%; */
}

.table-main-wrapper {
    width: 100%;
}

.table-main-wrapper table {
    border-radius: 15px;
    overflow: hidden;

}

.cus-height {
    height: calc(100vh - 108px);
    display: flex;
}

.table-wrape {
    padding: 0 50px;
}

/* .bg-video{
    background: url(../../assets/images/video/videotwo.mp4);
    background-size: cover;
    width: 100%;
    height: 500px;
} */
.vdo-frame {
    display: inline;
    height: 100%;
    opacity: .25;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

/* .new-roadmap-sec .roadmap-wrape{
background: var(--white);
} */
.new-roadmap-sec .roadmap-wrape {
    background: #ffffff26;
    /* min-height: calc(100vh - 85px); */
    padding: 0 30px;
}

.new-chart-sec .box-frame {
    overflow: hidden;
}

.new-roadmap-sec .head-section p,
.new-roadmap-sec .head-section h4 {
    color: var(--black);
}

.new-roadmap-sec .roadmap-wrape .title-wrape p:before {
    background: var(--blue);
}

.title-wrape p {
    font-size: 16px;
}

.d-flex-buy {
    display: flex;
}

.how-to-buy-wrape .right-section li:last-child {
    margin-bottom: 0;
}

#presaleSummary ul li:last-child {
    margin-bottom: 0;
}

.how-to-buy-wrape .inner-section {
    /* -ms-overflow-style: none; */
    /* height: calc(100vh - 200px); */
    /* overflow: auto; */
    scrollbar-width: none;
}

.new-howToBuy-sec {
    position: unset !important;
}

.new-howToBuy-sec .bg-white {
    height: unset;
}

.new-faq-sec .box-frame {
    background: var(--green) !important;
}

.new-faq-sec .faq-wrape {
    background: #0000;
}

.new-faq-sec .title-wrape p.head-label {
    color: #000;
    color: var(--black);
}

.new-faq-sec .faq-wrape .title-wrape p.head-label {
    font-size: 13px;
    padding-left: 13px;
}

.new-faq-sec .faq-wrape .title-wrape p {
    color: #000;
    color: var(--black);
    font-weight: 500;
}

.new-faq-sec .faq-wrape .title-wrape p {
    font-size: 16px;
}

.faq-wrape .title-wrape p.head-label,
.faq-wrape .title-wrape h4 {
    color: var(--black);
    font-weight: 600;
}

.px-30 {
    padding: 0 30px;
}

.new-faq-sec .faq-wrape .faq-section .accr-pad {
    box-shadow: 0 2px 1px -1px #0000000d, 0 1px 1px 0 #0000000a, 0 1px 3px 0 #0000000d;
    margin: 0;
    padding: 10px 20px;
}

.new-faq-sec .faq-wrape .faq-section {
    background: var(--white);
    border-radius: 20px;
    overflow: hidden;
}

.new-faq-sec .faq-wrape .faq-section .head-label,
.new-faq-sec .faq-wrape .accr-pad .MuiAccordionDetails-root p {
    color: #000;
    color: var(--black) !important;
}

.new-faq-sec .faq-wrape .faq-section .accr-pad.Mui-expanded .number {
    color: #2b5af3;
    color: var(--blue);
}

.new-faq-sec .faq-wrape .accr-pad .MuiAccordionDetails-root p b {
    color: var(--blue);
    font-weight: 600;
}

.new-faq-sec .faq-wrape .accr-pad svg,
.new-faq-sec .faq-wrape .faq-section .accr-pad.Mui-expanded svg {
    fill: var(--black);
}

.new-footer-sec .footer-wrape {
    background: var(--black);
    color: var(--white);
    padding: 0px;
    position: unset;
    top: 0;
    z-index: 9;
    width: 100%;
    min-height: 500px;
    height: 100%;
    border-radius: 30px;
    display: flex;
    align-items: center;
}

/* .new-footer-sec .bg-white {
    height: 70vh;
} */
.new-howToBuy-sec .py-80 {
    padding: 0px;
}

.new-howToBuy-sec .how-to-buy-wrape {
    padding: 100px 20px;
}

.new-faq-sec {
    margin-top: -40px;
}

.new-howToBuy-sec .how-to-buy-wrape .right-section h3 {
    top: 0px;
    position: relative;
}

.new-howToBuy-sec .right-section .section {
    min-height: 38vh;
}

.swiper-pagination-progressbar {
    top: -5px !important;
}

.new-table-sec .table-scroll .MuiPaper-elevation {
    box-shadow: none;
}

/* .chart-img{

margin-left: 35px;
} */
.read-more-text {
    font-family: var(--head-font);
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    font-size: 13px !important;
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.new-chart-sec {
    height: 1275px;
    position: sticky;
    top: 0;
}

.cus-sticky {
    position: sticky;
    top: 0;
    /* height: 100vh; */
    /* background-image: linear-gradient(45deg, white, #ffffff), url('../images/png/glassbgold.png'); */
}

.bg-white {
    background: white !important;
    height: 100vh;
}

.sticky-header {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    /* Ensures it stays above other content */
    padding: 10px 0;
}

.py-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.support-link {
    text-decoration: underline;
}

.faq-wrape .title-wrape p.head-label::before {
    background: var(--blue);
}

.faq-section .MuiAccordionSummary-content {

    align-items: center;
}

.roadmap-bullet-block .bullet-text-title {
    font-size: 13px;
    margin-bottom: -10px;
}

.bullet-text-sdk {
    margin-bottom: 8px;
}

/* .new-hero-sec{
    padding: 40px 0;
  } */
.chart-bg-gif {
    background-image: url(../images/video/videotwo.gif);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.new-hero-sec .hero-main-rapper {
    justify-content: center;
}

.footer-copyright .copyright {
    margin-bottom: 1px;
}

.copy-btn-sec .referral-copy-btn {
    padding: 0 10px;
    background: #f6f6f6;
}

.copy-btn-sec .copy-ic-bg {
    background: #f6f6f6;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copy-btn-sec .referral-copy-btn:hover {
    background: #f6f6f6;
}

/* .text-wrape h6{
    font-size: 0.9rem;
} */
.copy-btn-sec h1 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.stat .reward-btn,
.stat .reward-btn:hover {
    background: #000;
    color: #FFF;
}

.stat .reward-btn-wrapper {
    display: flex;
    align-items: center;
}

.reward-btn-wrapper .p-currency {
    margin-right: 10px;
}

.new-state-box .stat {
    width: 40%;
}

.audit-img-wrape {
    display: flex;
    justify-content: end;
    align-items: center;
}

.audit-img-wrape .audit-img {
    height: 86px;
    width: 208px;
}

.p-0 {
    padding: 0;
}

.header-cust-col .audit-img-wrape .audit-img {
    height: 47px;
    width: auto;
}

.audit-img-header .audit-text {
    color: var(--black);
    font-family: 'violetsans', sans-serif;
    font-size: 25px;
}

.cred-shields-sec .audit-img {
    height: 65px;
    width: auto;
}

.cred-wrapper {
    position: relative;
}


.cred-shields-sec {
    top: 40px;
    justify-content: center;
    margin-top: 20px;
}

.cred-shields-sec .timer-cred-text {
    margin-bottom: 10px;
    color: var(--black);
    font-family: 'violetsans', sans-serif;
    font-size: 17px;
    /* text-align: center; */
    /* padding: 11.5px 14px; */
    /* border: 1px solid #b4b4b4; */
    /* border-radius: 20px; */
    line-height: 19px;
}

.progress-modal-main .MuiModal-backdrop {
    background-color: rgb(0 0 0 / 73%);
}

.progress-modal-main .progress-modal-inner {
    min-width: 380px;
    max-width: 380px;
    border-radius: 10px;
    border: none !important;
    box-shadow: 8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25);
    /* background-color: #f6f6f6; */
    background: linear-gradient(137.48deg, #fff -47.56%, #f1eaf8 -24.98%, #f2ffff 7.47%, #edeeff 29.18%, #f1e4eb 53.96%, #efe7fc 74.57%, #e0e0e0 92.77%, #f2f7ff 131.8%, #fcfcff 149.12%, #fff 171.71%);
}

.buy-btn-main {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buy-btn-main .buy-btn,
.buy-btn-main .buy-btn:hover {
    border: 1px solid #0000;
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 24px;
    text-transform: uppercase;
    white-space: nowrap;
    background: var(--blue);
    color: var(--white);
    width: 100%;
}

.progress-modal-inner .buy-remain-text {
    margin-bottom: 15px;
    margin-top: 20px;
    color: var(--black);
    font-family: 'violetsans', sans-serif;
    font-size: 15px;
    text-align: center;
    padding: 0;
}

.progress-modal-inner .box-head-text {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    font-family: 'violetsans', sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.progress-modal-inner p {
    text-align: center;
    position: relative;
    width: 100%;
    padding-bottom: 0px;
    margin-bottom: 20px;
}

.modal-counter-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.modal-counter-box {
    border: 1px solid gray;
    padding: 10px;
    height: 70px;
    width: 70px;
    border-radius: 10px;
    transition: 0.5s ease-in-out;
    animation: rotateGradiant 5000ms infinite linear;
    -webkit-animation: rotateGradiant 5000ms infinite linear;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}

@keyframes rotateGradiant {
    25% {
        border: 1px solid #FF6C02;
    }

    50% {
        border: 1px solid #10DE62;
    }

    75% {
        border: 1px solid #8552f1;
    }

    100% {
        border: 1px solid #ff7876;
    }
}

.modal-counter-box .modal-counter-number-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    color: #000;
    padding: 0;
    margin: 0 0px 5px 0px;
    text-align: center;
    transition: 0.5s ease-in-out;
    animation: colorChange 5000ms infinite linear;
    -webkit-animation: colorChange 5000ms infinite linear;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
}

@keyframes colorChange {
    25% {
        color: #FF6C02;
    }

    50% {
        color: #10DE62;
    }

    75% {
        color: #8552f1;
    }

    100% {
        color: #ff7876;
    }
}

.modal-counter-box .modal-counter-number-text::after,
.modal-counter-box .modal-counter-days-text::after {
    display: none;
}

.modal-counter-box .modal-counter-days-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000;
    padding: 0;
    margin: 0;
    text-align: center;
}

.modal-counter-main {
    margin: auto;
}

.close-icon {
    height: 20px;
    width: 20px;
    object-fit: contain;
    position: absolute;
    right: 10px;
    top: 10px;
}

.progress-modal-header {
    margin-bottom: 35px;
}

.lowercase-text {
    text-transform: lowercase;
}

.progress-modal-header .progress-modal-title {
    font-family: violetsans, sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    max-width: max-content;
    position: relative;
}

.progress-modal-header .progress-modal-title::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 30px;
    right: 20%;
    bottom: -4px;
    background-color: blue;
}