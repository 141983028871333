:root {
      --blue: #2B5AF3;
      --green: #38FF8E;
      --black: #000000;
      --white: #ffffff;
      --headfont: 'violetsans';
      --bodyfont: 'Inter';
}

@font-face {
      font-family: 'violetsans';
      src: url(../fonts/VioletSans-Regular.ttf);
}

@font-face {
      font-family: 'Inter';
      src: url(../fonts/Inter-Regular.ttf);
}

.container {
      padding: 0px 3.25rem 0px 3.25rem;
      width: 100%;
      margin: auto;
      max-width: 1366px;
}

.grid-main .grid-item {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0px;
      padding: 0px;
}

.text-decoration-none {
      text-decoration: none !important;
}


/* start input css */
.input-pad {
      width: 100%;
      border: 1px solid #b4b4b4;
      border-radius: 16px;
      background: var(--white);
}

.input-pad input {
      border-radius: 6px;
      width: 100%;
      padding: 16.5px 14px;
      background-color: transparent;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Inter', sans-serif;
      height: 57px;
}

input::placeholder {
      opacity: 1;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #222222;
      border-width: 0px;
      border-radius: 6px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
      border-color: #222222 !important;
      border-width: 0px;
      border-radius: 6px;

}

.form-control-textarea:focus-visible {
      outline: none !important;
}

fieldset {
      border: 0px;
      border-color: #fff !important;
      display: none;
}

/* end input css */




/* my style start here */
*,
*::after,
*::before {
      box-sizing: border-box !important;
}

* {
      /* content: ""; */
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 15px;
      font-family: 'Inter', sans-serif;
      color: var(--black);
}

img {
      max-width: 100%;
      height: auto;
}

a {
      text-decoration: none;
}

.form-control fieldset {
      display: none;
}


header .mobile-menu-btn {
      display: none;
}

.home-hero-main {
      max-width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      min-height: 80vh;
      padding: 60px 3.25rem;
      gap: 20px;
      position: relative;
      height: calc(100vh - 70px);
}

.home-hero-main::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      content: "";
      display: inline-block;
      background: url(../images/jpg/5097410.jpg) no-repeat 0 0;
      background-size: cover;
      opacity: 0.75;
}

.home-hero-main.two::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      content: "";
      display: inline-block;
      background: url(../images/jpg/bg-2.jpg) no-repeat center center;
      background-size: cover;
      opacity: 1;
}

.home-hero-main .btn-wrape {
      display: flex;
      gap: 15px;
      padding: 20px 0;
}

.presale-wrape {
      padding: 2rem;
      max-width: 450px;
      position: relative;
      background: #ffffff;
      border-radius: 33px;
      width: 36%;
}

.presale-wrape::before {
      border-radius: 30px;
      border: 1px solid #ececec;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      pointer-events: none;
}

.presale-wrape::after {
      content: "";
      left: 0;
      top: 0;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: inline-block;
      pointer-events: none;
      background: url(../images/svg/Frame.svg) no-repeat;
}

.presale-wrape h4 {
      font-family: 'violetsans', sans-serif;
      font-weight: 400;
      font-size: 2rem;
      text-transform: uppercase;
      padding-bottom: 20px;
      position: relative;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
}

.presale-wrape h4 .label-wrape {
      position: relative;
      text-transform: capitalize;
}

.presale-wrape h4 .label-wrape i {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 5px;
      display: inline-block;
}

.presale-wrape h4 .label-wrape .open i {
      background: #18bf2e;
}

.presale-wrape h4 .label-wrape .close i {
      background: red;
}

.presale-wrape h4:after {
      content: "";
      width: 16px;
      height: 3px;
      background: var(--blue);
      position: absolute;
      left: 0;
      bottom: 10px;
      transition: all 0.15s linear 0.15s;
}

.presale-wrape:hover h4::after {
      width: 30px;
      transition: all 0.15s linear 0.15s;
}

.presale-wrape .footer-action {
      width: 100%;
}

.presale-wrape .footer-action .wallet-connetct .icon {
      width: 48px;
      height: 48px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 8px;
}

.pre-seed-sec .footer-action .submit-btn.submit-loader-btn {
      padding: 5px 30px;
}

.presale-wrape .footer-action .wallet-connetct .icon svg {
      width: 16px;
      height: 20px;
}

.presale-wrape .footer-action .submit-btn {
      background: var(--black);
      border-radius: 30px;
      color: var(--white);
      text-transform: uppercase;
      font-weight: 500;
      padding: 5px 30px 5px 5px;
      font-size: 15px;
      gap: 10px;
}

.presale-wrape .gray-pad {
      background: #f6f6f6;
      border-radius: 16px;
      padding: 18px 24px;
      margin-bottom: 20px;
}

.presale-wrape .gray-pad:last-child {
      margin-bottom: 0;
}

.presale-wrape .gray-pad .input-wrape {
      width: calc(50% - 10px);
}

.presale-wrape .gray-pad .inner-pad {
      display: flex;
      justify-content: space-between;
}

.presale-wrape .gray-pad p {
      font-weight: 500;
      color: var(--black);
      margin-bottom: 10px;
      text-align: left;
}

.presale-wrape .gray-pad h1 {
      font-size: 30px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 10px;
}

.presale-wrape .gray-pad h1 .p-currency {
      background: var(--green);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.presale-wrape .gray-pad h1 .p-currency svg {
      height: 24px;
}

.presale-wrape p {
      margin-bottom: 0;
      text-align: center;
      color: #444444;
}

.presale-wrape p b {
      font-weight: 600;
      color: var(--blue);
}

.presale-wrape p.base-price {
      border-top: 1px solid #dadada;
      padding-top: 10px;
      margin-top: 15px;
      margin-bottom: 0;
      text-align: center;
}

.presale-wrape .text-wrape {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
}

.presale-wrape .text-wrape .icon {
      width: 24px;
      height: 24px;
}

.presale-wrape .text-wrape .icon svg {
      width: 24px;
      height: 24px;
}

.presale-wrape .text-wrape h6 {
      display: flex;
      align-items: center;
      gap: 10px;
}

li.MuiMenuItem-root,
div.MuiSelect-select {
      display: flex;
      gap: 5px;
      align-items: center;
}

.MuiMenuItem-root .dd-icon,
.MuiSelect-select .dd-icon {
      width: 24px;
      height: 24px;
}

.MuiMenuItem-root .dd-icon svg,
.MuiSelect-select .dd-icon svg {
      max-width: 100%;
      height: auto;
}

.table-wrape {
      width: 100%;
      position: relative;
}

.table-wrape .MuiTableCell-head {
      background: var(--black);
      color: var(--white);
      border-bottom: 2px solid var(--green);
}

.table-wrape .table-icon {
      width: 36px;
      height: 36px;
      background: var(--green);
      border-radius: 8px;
      padding: 8px;
}

.table-wrape .table-icon svg {
      width: 18px;
      height: 18px;
}

.progress-wrape {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      margin: 20px 0 20px;
}

.progress-wrape p {
      width: 100%;
}

.progress-wrape .progress-pad {
      width: 100%;
      display: flex;
      padding: 10px 0;
}

.progress-wrape .progress-pad .MuiLinearProgress-root {
      width: 100%;
      height: 8px;
      border-radius: 8px;
      background: #efefef;
}

.progress-wrape .progress-pad .MuiLinearProgress-bar {
      background: var(--blue);
}

/* .hero-text-area {
      max-width: 400px;
      position: relative;
      width: 30%;
      z-index: 9;
} */

.hero-text-area h1,
.hero-text-area h1 span {
      margin-bottom: 15px;
      color: var(--black);
      font-family: 'violetsans', sans-serif;
      font-size: 30px;
      text-transform: uppercase;
      width: 250px;
      position: relative;
}

.hero-text-area p {
      color: #666666;
      line-height: 1.35;
      font-size: 16px;
}

.countdown-wrapper {
      display: flex;
      border: 1px solid #efefef;
      border-radius: 30px;
      padding: 30px;
      max-width: 450px;
      flex-wrap: wrap;
      position: relative;
      background: rgba(255, 255, 255, 1);
      width: 34%;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: 1px solid rgba(255, 255, 255, 1);
}

.countdown-wrapper .box-head-text {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
      width: 100%;
      font-family: 'violetsans', sans-serif;
      text-transform: uppercase;
      margin-bottom: 5px;
}

.countdown-wrapper .box-head-text{
margin-bottom: 20px;
position: relative;
}
.countdown-wrapper .box-head-text::after{
      content: "";
      width: 60px;
      height: 3px;
      background: var(--green);
      position: absolute;
      left: 50%;
      bottom: -6px;
      transition: all 0.15s linear 0.15s;
      margin-left: -30px;
}

.text-effect-1 .countdown-wrapper .box-head-text {
      text-transform: uppercase;
      background-image: linear-gradient(-225deg,
                  #000000 0%,
                  var(--blue) 29%,
                  var(--green) 100%);
      background-size: auto auto;
      background-clip: border-box;
      background-size: 200% auto;
      color: #fff;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textclip 2s linear infinite;
      display: inline-block;
}

@keyframes textclip {
      to {
            background-position: 200% center;
      }
}

.countdown-wrapper .box-head-text span {
      position: relative;
      margin: 0 15px;
}

.countdown-wrapper .box-head-text span::after,
.countdown-wrapper .box-head-text span::before {
      background-size: 300% 300%;
      background-repeat: no-repeat;
      border: 2px solid #fff;
      border-radius: 100% 100% 100% 0;
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      position: absolute;
      transform-origin: bottom left;
      left: -15px;
      top: 10px;
}

.countdown-wrapper .box-head-text span::after {
      -webkit-animation: hour-glass-one 4s infinite linear;
      animation: hour-glass-one 4s infinite linear;
}

.countdown-wrapper .box-head-text span:before {
      -webkit-animation: hour-glass-two 4s infinite linear both;
      animation: hour-glass-two 4s infinite linear both;
}

@-webkit-keyframes hour-glass-one {
      0% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: -40% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(0deg);
      }

      40% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(0deg);
      }

      50% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      50.001% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0% 40%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      90% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0% -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      100% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }
}

@keyframes hour-glass-one {
      0% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: -40% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(0deg);
      }

      40% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(0deg);
      }

      50% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      50.001% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0% 40%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      90% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0% -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      100% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }
}

@-webkit-keyframes hour-glass-two {
      0% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 40%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      40% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      50% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      50.001% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: -40% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      90% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      100% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(540deg);
      }
}

@keyframes hour-glass-two {
      0% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 40%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      40% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(180deg);
      }

      50% {
            background-image: linear-gradient(45deg, #2eec71, #2eec71 50%, transparent 50%, transparent);
            background-position: 0 -50%;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      50.001% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: -40% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      90% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(360deg);
      }

      100% {
            background-image: linear-gradient(45deg, transparent, transparent 50%, var(--blue) 50%, var(--blue));
            background-position: 100% 0;
            transform: translate(50px, 0) rotate(135deg) translateY(35px) rotate(540deg);
      }
}

.countdown-wrapper p {
      text-align: center;
      position: relative;
      width: 100%;
      padding-bottom: 30px;
      margin-bottom: 20px;
}

.countdown-wrapper p::after {
      content: "";
      width: 60px;
      height: 3px;
      background: var(--green);
      position: absolute;
      left: 50%;
      bottom: 10px;
      transition: all 0.15s linear 0.15s;
      margin-left: -30px;
}

.countdown-wrapper .countdown-pad {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.countdown-wrapper .time-section {
      width: calc(100% - 10px);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;
}

.countdown-wrapper .time-section small {
      color: #666666;
}

.countdown-wrapper .time-section:nth-child(even) {
      width: 3px;
}

.countdown-wrapper .time-section .time {
      font-weight: 500;
      font-size: 32px;
      width: 100%;
      color: var(--blue);
}

.option-2 .progress-wrape {
      border: 0;
      padding: 0;
      margin: 0 0 20px;
}

.option-2 .presale-wrape {
      box-shadow: 5px 10px 30px rgba(0, 0, 0, .15);
      border-radius: 30px;
}

.option-2 .presale-wrape::after,
.option-2 .presale-wrape::before {
      display: none;
}

.option-2 .presale-wrape h4 {
      align-items: center;
}

.option-2 .presale-wrape h4:after {
      background: var(--green);
}

.option-2 .header-right-main a {
      background: var(--blue);
}

.option-2 .header-right-main a svg,
.option-2 .header-right-main a svg path {
      fill: var(--white);
}

.option-2 header .head-btn {
      background: var(--green);
      color: var(--black);
}

.option-2 .header-nav-main .header-nav-link:hover:after,
.option-2 .header-nav-main .header-nav-link.active:after {
      background: var(--blue);
}

.option-2 .presale-wrape .gray-pad h1 .p-currency {
      background: var(--blue);
}

.option-2 .progress-wrape .progress-pad .MuiLinearProgress-bar {
      background: var(--green);
}

.option-2 .presale-wrape .footer-action .submit-btn {
      width: 100%;
      justify-content: flex-start;
}

.option-2 .hero-text-area h1,
.option-2 .hero-text-area h1 span {
      line-height: 1.65;
}

.option-2 .hero-text-area h1 span {
      padding-left: 10px;
}

.option-2 .hero-text-area h1::after {
      content: "";
      background: url(../images/svg/text-highlighter.svg) no-repeat;
      width: 255px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
}

.option-2 .countdown-wrapper p::after {
      background: var(--blue);
}

.option-2 .countdown-wrapper .countdown-pad {
      gap: 10px;
}

.option-2 .countdown-wrapper .time-section .time {
      background: var(--black);
      border-radius: 8px;
      padding: 15px 5px;
      color: var(--green);
      margin-bottom: 10px;
}

.option-2 .countdown-wrapper .time-section:nth-child(even) {
      display: none;
}

.option-2 .countdown-wrapper .time-section {
      width: 25%;
}

#bran-symbol #dark {
      fill: var(--white);
}

#bran-symbol #light {
      fill: #c8c8c8;
}

#bran-symbol #mid {
      fill: #888888;
}


.one .hero-text-area {
      width: calc((100% / 2) - 290px);
}

.one .presale-wrape {
      width: 450px;
      max-width: 450px;
}

.one .countdown-wrapper {
      width: calc((100% / 2) - 190px);
      padding: 1.5rem;
}

.glass-bgs .presale-wrape {
      z-index: 9;
}

.glass-bgs .glass-bg {
      position: absolute;
      z-index: 1;
      right: -7rem;
      bottom: 0;
}

.glass-bgs .home-hero-main,
.glass-bgs .table-wrape {
      position: relative;
      z-index: 9;
      overflow: hidden;
}

.glass-bgs .home-hero-main::before {
      display: none;
}

.glass-bgs .countdown-wrapper {
      border-color: #ffffff;
      border-bottom-width: 2px;
      /* background-color: #fff9; */
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      z-index: 9;
}

tbody {
      border-bottom: 1px solid #d9d9d9;
}

.chart-wrape {
      position: relative;
      width: 100%;
      margin-bottom: 60px;
      z-index: 9;
}

.chart-wrape .chart-section {
      /* max-width: 450px; */
      margin: auto;
}

.chart-wrape .chart-section .App {
      position: relative;
}

.chart-wrape .apexcharts-canvas {
      max-width: 100%;
      height: auto;
}

.chart-wrape .head-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      padding: 30px 0 60px;
}

.chart-wrape .title-wrape {
      max-width: 560px;
      text-align: center;
      margin: auto;
      padding: 0 15px;
}

.chart-wrape .title-wrape p {
      color: var(--black);
      text-transform: capitalize;
      margin-top: 12px;
      font-size: 14px;
      line-height: 1.5;
}

.chart-wrape p.head-label,
.chart-wrape .title-wrape h4 {
      position: relative;
      white-space: nowrap;
      color: var(--black);
      font-family: var(--headfont);
}

.chart-wrape h4 {
      font-size: 26px;
      display: table;
      margin: auto;
      line-height: 1.3;
}

.chart-wrape h4:after {
      background: url(../images/svg/our-journey-frame.svg) no-repeat 0 0;
      position: absolute;
      right: -6px;
      bottom: 0px;
      display: inline-block;
      width: 140px;
      height: 38px;
      content: "";
      background-size: 100%;
}

.chart-wrape p.head-label {
      font-size: 10px;
      padding-left: 10px;
      margin-bottom: 10px;
}

.chart-wrape p.head-label::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      display: inline-block;
      background: var(--green);
      border-radius: 50%;
      left: calc(50% - 35px);
      top: 50%;
      transform: translateY(-50%);
}

svg {
      width: 100%;
      height: auto;
}

.roadmap-wrape {
      width: 100%;
      position: relative;
      overflow: hidden;
      background: var(--blue);
      padding: 30px 0;
}

.roadmap-wrape .head-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      /* padding: 30px 0 60px; */
      padding: 10px 0 30px;
}

.roadmap-wrape .title-wrape p,
.roadmap-wrape .title-wrape h4 {
      position: relative;
      white-space: nowrap;
      color: var(--white);
      font-family: var(--headfont);
}

.roadmap-wrape .title-wrape h4 {
      font-size: 26px;
}

.roadmap-wrape .title-wrape h4:after {
      background: url(../images/svg/our-journey-frame.svg) no-repeat 0 0;
      position: absolute;
      right: -5px;
      bottom: -2px;
      display: inline-block;
      width: 140px;
      height: 38px;
      content: "";
      background-size: 100%;
}

.roadmap-wrape .title-wrape p {
      font-size: 12px;
      padding-left: 12px;
      margin-bottom: 10px;
}

.roadmap-wrape .title-wrape p::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      display: inline-block;
      background: var(--green);
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
}

.roadmap-wrape .container {
      width: 1366px;
      position: relative;
      max-width: 90%;
}

.roadmap-wrape .roadmap-card-block.is-full {
      width: 100%;
      height: 100%;
      background-color: var(--white);
      border-radius: 0.9375rem;
      padding: 2.5rem;
      overflow: hidden;
}

.roadmap-wrape .swiper-slide {
      height: 19.375rem !important;
}

.roadmap-bullet {
      grid-column-gap: 0.75rem;
      grid-row-gap: 0.75rem;
      margin-bottom: 1rem;
      display: flex;
}

.roadmap-wrape .bullet-icon svg {
      width: 16px;
      height: 16px;
}

.roadmap-wrape .roadmap-title {
      font-family: var(--headfont);
      margin-bottom: 1.5rem;
      /* margin-bottom: 0px; */
      font-size: 16px;
      line-height: 120%;
}

.roadmap-wrape .bullet-text {
      font-size: 13px;
}

.roadmap-wrape .mySwiper {
      padding-bottom: 3rem;
      overflow: visible;
}

.roadmap-wrape .swiper-pagination-progressbar.swiper-pagination-horizontal {
      height: 1px;
      background: rgb(43 90 243 / 25%);
      /* background: rgba(255, 255, 255, 0.25); */
}

.roadmap-wrape .swiper-pagination-progressbar.swiper-pagination-horizontal span {
      background: var(--blue);
}

.roadmap-wrape .controls {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      height: 36px;
}

.roadmap-wrape .controls .swiper-button-prev::after,
.roadmap-wrape .controls .swiper-button-next:after {
      display: none;
}

.roadmap-wrape .controls .swiper-button-prev,
.roadmap-wrape .controls .swiper-button-next {
      background: var(--white);
      width: 36px;
      height: 36px;
      padding: 10px;
      position: relative;
      left: 0;
      right: 0;
      border-radius: 50%;
}

.roadmap-wrape .read-bullet-text {
      font-family: var(--head-font);
      cursor: pointer;
      text-decoration: underline;
      display: inline-block;
}

.roadmap-wrape .roadmap-content-block {
      height: 72%;
      position: relative;
      overflow: auto;
      transition: all 0.25s ease-in-out 0.25s;
      scrollbar-width: none;
}

.roadmap-wrape .roadmap-content-block::-webkit-scrollbar {
      width: 0;
      background: transparent;
}


.roadmap-wrape .roadmap-content-block:after {
      content: "";
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%, #FFFFFF 100%);
      height: 4.375rem;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 0;
      /* top: calc(100% - 4.375rem); */
      top: calc(100% - 3.375rem);
}

.roadmap-wrape .roadmap-content-block.active:after {
      display: none;
}

.roadmap-wrape .roadmap-content-block.active:before {
      display: block;
}

.roadmap-wrape .roadmap-content-block:before {
      content: "";
      background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 45.05%, rgba(255, 255, 255, 0) 100%);
      height: 4.375rem;
      display: none;
      left: 0;
      position: absolute;
      top: -2rem;
      width: 100%;
      z-index: 2;
      display: none;
}

.modal-wrape .modal-pad {
      border: 0;
      border-radius: 24px;
      max-width: 450px;
      width: 90%;
}

.modal-wrape .modal-pad h2 {
      text-transform: uppercase;
      font-size: 30px;
      margin-bottom: 30px;
      font-family: var(--headfont);
      font-weight: 600;
}

.modal-wrape .modal-pad h2 span.frame {
      display: inline-block;
      position: relative;
      font-size: 30px;
      line-height: 40px;
      padding: 0px 10px 3px;
}

.modal-wrape .modal-pad h2 span.frame:after {
      background: url(../images/svg/modal-frame.svg) no-repeat 0 0;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
}

.modal-wrape .modal-pad p {
      font-size: 16px;
      font-weight: 500;
}

.modal-wrape .modal-pad .input-wrape {
      margin-bottom: 15px;
}

.modal-wrape .modal-pad a.modal-close {
      width: 32px;
      height: 32px;
      padding: 6px 11px 0;
      position: absolute;
      right: 10px;
      top: 10px;
      background: var(--green);
      border-radius: 50%;
}

.faq-wrape {
      width: 100%;
      background: var(--black);
      padding: 50px 0;
}

.faq-wrape .head-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      padding: 30px 0 60px;
}

.faq-wrape .title-wrape p {
      color: var(--white);
      text-transform: capitalize;
      margin-top: 20px;
      font-size: 14px;
}

.faq-wrape .title-wrape p.head-label,
.faq-wrape .title-wrape h4 {
      position: relative;
      white-space: nowrap;
      color: var(--white);
      font-family: var(--headfont);
}

.faq-wrape .title-wrape h4 {
      font-size: 26px;
      display: table;
}

.faq-wrape .title-wrape h4:after {
      background: url(../images/svg/our-journey-frame.svg) no-repeat 0 0;
      position: absolute;
      right: -5px;
      bottom: -2px;
      display: inline-block;
      width: 140px;
      height: 38px;
      content: "";
      background-size: 100%;
}

.faq-wrape .title-wrape p.head-label {
      font-size: 10px;
      padding-left: 10px;
      margin-bottom: 10px;
}

.faq-wrape .title-wrape p.head-label::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      display: inline-block;
      background: var(--green);
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
}

.faq-wrape .faq-section .MuiPaper-rounded {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.25)
}

.faq-wrape .faq-section .head-label {
      color: var(--white);
      margin-right: 10px;
      font-weight: 500;
      padding-left: 5px;
      font-size: 15px;
      font-family: var(--headfont);
}

.faq-wrape .faq-section .number {
      margin: 0;
      font-family: Inter, sans-serif;
      font-weight: 600;
      font-size: 1rem;
      line-height: normal;
      letter-spacing: 0.00938em;
      color: var(--green);
}

.faq-wrape .accr-pad {
      /* background: rgba(255, 255, 255, 0.15); */
      background: none;
}

.faq-wrape .accr-pad svg {
      fill: var(--white);
      opacity: 0.6;
}

.faq-wrape .accr-pad .MuiAccordionDetails-root {
      padding: 10px 0;
}

.faq-wrape .accr-pad .MuiAccordionDetails-root p {
      color: var(--white);
      font-size: 13px;
      font-weight: 400;
}

.faq-wrape .accr-pad .MuiAccordionDetails-root p b {
      color: var(--green);
      font-size: 13px;
      font-weight: 400;
}

.faq-wrape .faq-section .MuiPaper-rounded .MuiAccordionSummary-root {
      padding: 0;
      min-height: inherit;
}

.faq-wrape .faq-section .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
}

.faq-wrape .faq-section .accr-pad {
      padding: 10px;
}

.faq-wrape .faq-section .accr-pad.Mui-expanded .head-label {
      color: var(--green);
}

.faq-wrape .faq-section .accr-pad.Mui-expanded svg {
      fill: var(--green);
      opacity: 1;
}

.faq-wrape .faq-section .accr-pad.Mui-expanded .number {
      color: var(--white);
}

.how-to-buy-wrape {
      width: 100%;
      padding: 80px 0;
}

.how-to-buy-wrape .head-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: center;
      padding: 0 0 60px;
}

.how-to-buy-wrape .title-wrape {
      max-width: 560px;
      margin: auto;
}

.how-to-buy-wrape .title-wrape p {
      color: var(--black);
      text-transform: capitalize;
      margin-top: 20px;
      font-size: 14px;
}

.how-to-buy-wrape p.head-label,
.how-to-buy-wrape .title-wrape h4 {
      position: relative;
      white-space: nowrap;
      color: var(--black);
      font-family: var(--headfont);
}

.how-to-buy-wrape h4 {
      font-size: 26px;
      display: table;
      margin: auto;
      line-height: 1.35;
}

.how-to-buy-wrape h4:after {
      background: url(../images/svg/how-to-buy-frame.svg) no-repeat 0 0;
      position: absolute;
      right: -5px;
      bottom: -2px;
      display: inline-block;
      width: 244px;
      height: 40px;
      content: "";
      background-size: 100%;
}

.how-to-buy-wrape p.head-label {
      font-size: 10px;
      padding-left: 40px;
      margin-bottom: 10px;
}

.how-to-buy-wrape p.head-label::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      display: inline-block;
      background: var(--green);
      border-radius: 50%;
      left: 30px;
      top: 50%;
      transform: translateY(-50%);
}

.how-to-buy-wrape .container {
      display: flex;
}

.how-to-buy-wrape .left-section {
      margin: auto;
      width: 100%;
}

.how-to-buy-wrape .navContainer {
      position: sticky;
      top: 100px;
}

.how-to-buy-wrape .navlink {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
}

.how-to-buy-wrape .navlink b {
      font-size: 20px;
      color: var(--blue);
      font-weight: 500;
      text-transform: uppercase;
}

.how-to-buy-wrape .navlink span {
      width: 100%;
      display: block;
      font-size: 13px;
}

.how-to-buy-wrape .navlink.is-sticky::after {
      content: "";
      width: 15px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: -10px;
      background: var(--green);
      display: inline-block;
}

.how-to-buy-wrape .navlink {
      margin-bottom: 20px;
      margin-top: 10px;
}

/* .how-to-buy-wrape .right-section {
      width: calc(100% - 300px);
} */

.how-to-buy-wrape .right-section .section {
      min-height: 80vh;
      display: flex;
      padding: 0 0 30px;
      flex-wrap: wrap;
      align-content: flex-start;
}

.how-to-buy-wrape .right-section h3 {
      color: var(--black);
      font-size: 24px;
      font-family: var(--headfont);
      text-transform: uppercase;
      margin-bottom: 30px;
      position: relative;
      padding: 20px 20px 20px 0;
      width: 100%;
      background: var(--white);
      position: sticky;
      top: 79px;
}

.how-to-buy-wrape .right-section h3:after {
      content: "";
      width: 16px;
      height: 3px;
      background: var(--blue);
      position: absolute;
      left: 0;
      bottom: 10px;
      transition: all 0.15s linear 0.15s;
}

.how-to-buy-wrape .right-section ul,
.how-to-buy-wrape .right-section li {
      list-style: none inside;
      margin-bottom: 30px;
      font-size: 15px;
}

.how-to-buy-wrape .right-section li {
      display: flex;
      /* flex-wrap: wrap; */
}

.how-to-buy-wrape .right-section li svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
}

.how-to-buy-wrape .right-section li b {
      width: 100%;
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
      color: var(--black);
}

.how-to-buy-wrape .right-section li p {
      width: 100%;
}

#stepByStep li {
      flex-wrap: nowrap;
}

.me {
      height: 100vh;
}

.me .swiper,
.me .swiper-wrapper {
      width: 100%;
      height: 100%;
}

.me .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100% !important;
}

.me .swiper-slide:first-child {
      align-items: flex-start;
}

.me .swiper-slide img {
      display: block;
      width: 100%;
}


.stick-hero.glass-bgs .header-main {
      padding: 12px 0px 12px;
}

.stick-hero.glass-bgs .header-main.is-sticky {
      padding: 7px 0px 7px;
}

.stick-hero.glass-bgs .header-main.is-sticky button.btn {
      padding: 13px 22px;
      font-size: 13px;
}

.stick-hero.glass-bgs .header-main.is-sticky .header-nav-main .header-nav-link {
      font-size: 13px;
}

.stick-hero.glass-bgs .home-hero-main {
      position: sticky;
      top: 0;
      z-index: 2;
      padding: 20px 3.25rem;
}

.stick-hero.glass-bgs .presale-wrape .footer-action .wallet-connetct .icon {
      width: 38px;
      height: 38px;
}

.stick-hero.glass-bgs .presale-wrape .footer-action .submit-btn {
      padding: 5px 23px 5px 5px;
      font-size: 13px;
}

.footer-action .Btn-main-box {
      display: flex;
      justify-content: space-around;
}

.stick-hero.glass-bgs .progress-wrape {
      padding: 15px 0;
      margin: 10px 0;
}

.stick-hero.glass-bgs .presale-wrape p {
      font-size: 14px;
}

.stick-hero.glass-bgs .presale-wrape .gray-pad p {
      font-size: 14px;
}

.stick-hero.glass-bgs .progress-wrape .progress-pad {
      padding: 10px 0;
}

.stick-hero.glass-bgs .presale-wrape .gray-pad h1 .p-currency {
      width: 36px;
      height: 36px;
      background: var(--blue);
}

.stick-hero.glass-bgs .presale-wrape .gray-pad h1 .p-currency svg {
      height: 20px;
}

.stick-hero.glass-bgs .progress-wrape .progress-pad .MuiLinearProgress-bar {
      background: var(--green);
}

.stick-hero.glass-bgs .semi-parallax-wrape {
      width: 100%;
      padding: 13px 0 0;
      z-index: 9;
      position: relative;
      overflow: hidden;
      height: calc(100vh - 57px);
}

.stick-hero.glass-bgs .semi-parallax-wrape .container {
      /* z-index: 9;
      position: relative; */
}

.stick-hero.glass-bgs .presale-wrape::after {
      display: none;
}

.stick-hero.glass-bgs .semi-parallax-wrape .glass-bg {
      background: #ffffff;
}

.stick-hero.glass-bgs .presale-wrape .text-wrape {
      padding: 5px 0 10px;
}

.stick-hero.glass-bgs .presale-wrape {
      padding: 1rem 1.5rem;
}

.stick-hero.glass-bgs .chart-wrape .head-section {
      padding: 10px 0 40px;
}

.stick-hero.glass-bgs .input-pad input {
      height: 47px;
      padding: 11.5px 14px;
}

.stick-hero.glass-bgs div.MuiSelect-select {
      min-height: 44px !important;
      padding: 12.5px 32px 12.5px 14px;
}

.stick-hero.glass-bgs .presale-wrape .gray-pad {
      padding: 10px 15px;
      margin-bottom: 15px;
}

.stick-hero.glass-bgs .presale-wrape h4 {
      font-size: 1.5rem;
      padding-bottom: 13px;
      margin-bottom: 5px;
}

.semi-parallax-wrape .box-frame {
      position: relative;
      /* background: #efefef; */
      border-radius: 30px;
      height: calc(100vh - 85px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
      /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
      z-index: 9;
}

.semi-parallax-wrape .box-frame::-webkit-scrollbar {
      display: none;
}


.semi-parallax-wrape .box-frame .frame {
      display: flex;
      /* align-items: center; */
      align-items: flex-start;
      justify-content: center;
      min-height: 100%;
      position: sticky;
      top: 0;
      background: #ffffff;
      /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
      min-height: 100vh;
}

.semi-parallax-wrape .swiper-slide {
      position: sticky !important;
      top: 0 !important;
      height: 270px !important;
}

.semi-parallax-wrape .roadmap-wrape .mySwiper {
      padding-bottom: 4rem;
}

.semi-parallax-wrape .frame.glass {
      border-color: #ffffff;
      border-bottom-width: 2px;
      /* background-color: #fff9; */
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.25);
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 30px;
      /* min-height: calc(100vh - 85px); */
      min-height: 100%;
      align-items: center;
}

.semi-parallax-wrape .frame.chart {
      background: #dbe8f3;
}

.semi-parallax-wrape .frame.chart .chart-img-wrape {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 15px;
      max-width: 670px;
      margin: auto;
}

.semi-parallax-wrape .frame.chart .head-section {
      padding: 0px 0 20px;
}

.semi-parallax-wrape .frame.chart .chart-img-wrape .chart-pad {
      max-width: 60%;
}

.semi-parallax-wrape .frame.chart .chart-img-wrape .stack-lagend {
      max-width: 40%;
}

.semi-parallax-wrape .frame.how-to-buy {
      background: #f7f7f7;
      min-height: 200vh;
}

.semi-parallax-wrape .frame.road {
      background: var(--blue);
}

.semi-parallax-wrape .frame.faq {
      background: #000000;
      align-items: center;
}

.semi-parallax-wrape .how-to-buy-wrape {
      padding: 0;
}

.semi-parallax-wrape .how-to-buy-wrape .right-section h3 {
      top: 0;
      background: #f7f7f7;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      color: var(--blue);
}

.semi-parallax-wrape .how-to-buy-wrape .right-section h3:after {
      display: none;
}

body.parallax-work {
      overflow-x: hidden;
}

.parallax-work.glass-bgs .countdown-wrapper {
      width: 100%;
}

.parallax-work.glass-bgs .container-pad {
      width: 100%;
      display: flex;
}

.parallax-work.glass-bgs .hero-text-area {
      width: 100%;
}

.parallax-work.glass-bgs .presale-wrape {
      width: 100%;
}

.p-wrapes .swiper.swiper-vertical {
      height: calc(100vh - 60px) !important;
}

.p-wrapes .swiper-slide {
      height: calc(100vh - 60px) !important;
      background: red;
}

.p-wrapes .swiper-slide img {
      max-width: 240px;
}

.tst {
      height: calc(100vh - 60px);
      border: 2px dashed blue;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
}

/* .section {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
    } */

.ScrollingSections {
      font-family: Arial, sans-serif;
      overflow: hidden;
}

.home-hero-main.animation-hide {
      display: none;
}

.b-hide.glass-bgs .semi-parallax-wrape {
      width: 100%;
      padding: 83px 0 0;
      z-index: 9;
      position: sticky;
      overflow: hidden;
      height: 100vh;
      top: 0;
      display: flex;
      align-items: center;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame {
      height: calc(100vh - 115px);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      border: 1px solid white;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.chart {
      min-height: 150%;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road {
      min-height: 150%;
}

.b-hide.glass-bgs .roadmap-wrape .head-section {
      padding: 0px 0 35px;
}

.b-hide.glass-bgs .roadmap-wrape .swipper-main-wrape {
      padding: 0 30px;
}

.b-hide.glass-bgs .roadmap-wrape .container {
      max-width: calc(100% - 60px);
      padding: 0;
}

.b-hide.glass-bgs .roadmap-wrape .swipper-main-wrape+.container {
      padding: 0 30px;
}

.b-hide.glass-bgs .roadmap-wrape .roadmap-content-block {
      overflow: auto;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.b-hide.glass-bgs .roadmap-wrape .roadmap-content-block::-webkit-scrollbar {
      display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.b-hide.glass-bgs .roadmap-wrape .roadmap-content-block {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
}

.b-hide.glass-bgs .roadmap-wrape .roadmap-content-block::after,
.b-hide.glass-bgs .roadmap-wrape .roadmap-content-block::before {
      display: none;
}

.b-hide.glass-bgs .semi-parallax-wrape .swiper-slide {
      height: 100% !important;
}

.b-hide.glass-bgs .semi-parallax-wrape .swiper-slide .roadmap-bullet:last-child {
      margin-bottom: 0;
}

.b-hide.glass-bgs .semi-parallax-wrape .roadmap-wrape .mySwiper {
      padding-bottom: 2rem;
      height: calc(100vh - 250px);
      padding-right: 1px;
      max-height: 350px;
}

.b-hide.glass-bgs .roadmap-wrape .roadmap-card-block.is-full {
      padding: 1.5rem;
}

.b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame .frame.faq {
      background: var(--green);
      height: 130%;
      padding-bottom: 12%;
}

.b-hide.glass-bgs .semi-parallax-wrape .faq-wrape {
      /* background: var(--green); */
      background: transparent;
}

.b-hide.glass-bgs .faq-wrape .title-wrape p.head-label,
.b-hide.glass-bgs .faq-wrape .title-wrape h4 {
      color: var(--black);
}

.faq-wrape .title-wrape h4:after {
      background-image: url(../images/svg/our-journey-frame-blue.svg);
      right: -8px;
      left: 131px;
}

.b-hide.glass-bgs .faq-wrape .faq-section {
      background: var(--white);
      border-radius: 20px;
      overflow: hidden;
}

.b-hide.glass-bgs .faq-wrape .accr-pad svg {
      fill: var(--black);
}

.b-hide.glass-bgs .faq-wrape .faq-section .accr-pad.Mui-expanded .number {
      color: var(--blue);
}

.b-hide.glass-bgs .faq-wrape .accr-pad .MuiAccordionDetails-root p {
      color: var(--black);
}

.b-hide.glass-bgs .faq-wrape .accr-pad .MuiAccordionDetails-root p b {
      color: var(--blue);
      font-weight: 600;
}

.b-hide.glass-bgs .faq-wrape .faq-section .accr-pad {
      padding: 10px 20px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.05);
      margin: 0;
}

.b-hide.glass-bgs .faq-wrape .title-wrape p.head-label::before {
      background: var(--blue);
}

.b-hide.glass-bgs .faq-wrape .title-wrape p {
      color: var(--black);
      font-weight: 500;
}

.b-hide.glass-bgs .faq-wrape .title-wrape h4 {
      font-weight: 600;
}

.b-hide.glass-bgs .faq-wrape .faq-section .head-label {
      color: var(--black);
}

.b-hide.glass-bgs .footer-wrape {
      width: 100%;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame {
      min-height: calc(100vh - 117px);
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.how-to-buy {
      height: 200%;
      /* background: red; */
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.how-to-buy .how-to-buy-wrape {
      /* border: 2px dashed green; */
      height: 100%;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.how-to-buy .how-to-buy-wrape .right-section {
      overflow: auto;
      height: 100%;
      /* border: 2px solid pink; */
}

.b-hide.glass-bgs .inner-section::-webkit-scrollbar {
      display: none;
}

.b-hide.glass-bgs .inner-section {
      overflow: auto;
      height: calc(100vh - 200px);
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
}

.b-hide.glass-bgs .how-to-buy-wrape .right-section .section {
      /* padding: 0 0 200px;
      min-height: 100vh; */
      padding: 0;
      min-height: inherit;
}

.b-hide.glass-bgs .how-to-buy-wrape .right-section .section:last-child {
      padding: 0 0 100px;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.footer {
      background: var(--black);
      align-items: center;
}


.how-to-buy-wrape h4 {
      text-align: left;
}

.how-to-buy-wrape h4 span {
      width: 100%;
      font-size: 26px;
      font-family: var(--headfont);
      display: block;
}

.how-to-buy-wrape .navContainer {
      top: 0;
      display: flex;
}

.how-to-buy-wrape .title-wrape h4 {
      display: flex;
      gap: 18px
}

.how-to-buy-wrape h4:after {
      right: inherit;
      left: 112px;
}

.countdown-pad .size-medium.flip-countdown {
      width: 100% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
}

.countdown-pad .size-medium .flip-countdown-piece {
      margin: 0 !important;
}

.countdown-pad .size-medium .flip-countdown-piece .flip-countdown-title {
      color: #666 !important;
      font-size: 15px !important;
}

.countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card {
      width: 100% !important;
      justify-content: center !important;
      gap: 5px;
}

.countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec {
      font-size: 30px !important;
      width: 30px !important;
      font-family: var(--headfont) !important;
      height: 44px !important;
      padding-bottom: 22px !important;
}

.countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span {
      font-family: var(--headfont) !important;
}

.countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__top {
      font-size: 30px !important;
      width: 30px !important;
      height: 28px !important;
      line-height: 43px !important;
}

.countdown-pad .flip-countdown.size-medium .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec span.card__bottom {
      font-size: 30px !important;
      width: 30px !important;
      height: 21px !important;
      line-height: 31px !important;
}

.countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back {
      position: absolute !important;
      top: 0 !important;
      height: 100% !important;
      left: 0% !important;
      pointer-events: none !important;
      width: 100% !important;
      font-size: 27px !important;
      /* background: red; */
      line-height: 30px !important;
      /* padding-top: 7px; */
}

.countdown-pad .flip-countdown .flip-countdown-piece .flip-countdown-card .flip-countdown-card-sec .card__back::before {
      height: 22px !important;
      font-size: 30px !important;
      line-height: 44px !important;
}

/* .b-hide.glass-bgs .header-main {
      padding: 12px 0px 12px;
}

.b-hide.glass-bgs .header-main.is-sticky {
      padding: 7px 0px 7px;
}

.b-hide.glass-bgs .header-main.is-sticky button.btn{
      padding: 13px 22px;
      font-size: 13px;
}

.b-hide.glass-bgs .header-main.is-sticky .header-nav-main .header-nav-link {
      font-size: 13px;
}



.b-hide.glass-bgs .progress-wrape .progress-pad .MuiLinearProgress-bar {
      background: var(--green);
}
.b-hide.glass-bgs .semi-parallax-wrape {
      width: 100%;
      padding: 13px 0 0;
      z-index: 9;
      position: relative;
      overflow: hidden;
      height: calc(100vh - 60px);
}



*/

.b-hide.glass-bgs .presale-wrape .footer-action .wallet-connetct .icon {
      width: 38px;
      height: 38px;
}

.b-hide.glass-bgs .presale-wrape .footer-action .submit-btn {
      padding: 5px 23px 5px 5px;
      font-size: 13px;
}

.b-hide.glass-bgs .progress-wrape {
      padding: 10px 0;
      margin: 10px 0;
}

.b-hide.glass-bgs .presale-wrape p {
      font-size: 14px;
}

.b-hide.glass-bgs .presale-wrape .gray-pad p {
      font-size: 14px;
      margin-bottom: 6px;
}

.b-hide.glass-bgs .progress-wrape .progress-pad {
      padding: 10px 0;
}

.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency {
      width: 36px;
      height: 36px;
      background: var(--blue);
}

.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency svg {
      height: 20px;
}

.b-hide.glass-bgs .semi-parallax-wrape .glass-bg {
      background: #ffffff;
}

.b-hide.glass-bgs .presale-wrape .text-wrape {
      padding: 2px 0 6px;
}

.b-hide.glass-bgs .presale-wrape {
      padding: 1rem 1.5rem;
}

.b-hide.glass-bgs .chart-wrape .head-section {
      padding: 5px 0 20px;
}

.b-hide.glass-bgs .input-pad input {
      height: 47px;
      padding: 11.5px 14px;
}

.b-hide.glass-bgs .how-to-buy-wrape .right-section h3 {
      padding: 10px;
      background: #ffffff;
}

.b-hide.glass-bgs .how-to-buy-wrape .right-section p {
      margin-bottom: 30px;
}

.b-hide.glass-bgs .semi-parallax-wrape .frame.chart .chart-img-wrape {
      max-width: 77%;
      position: relative;
      top: -35px;
      padding: 25px 20px 20px;
      gap: 40px;
}

.b-hide.glass-bgs .semi-parallax-wrape .frame.chart .chart-img-wrape .chart-pad {
      width: auto;
      height: 365px;
      display: flex;
      align-items: flex-start;
}

.b-hide.glass-bgs .semi-parallax-wrape .frame.chart .chart-img-wrape .chart-pad img {
      max-height: 100%;
      max-width: 100%;
}

.b-hide.glass-bgs div.MuiSelect-select {
      min-height: 44px !important;
      padding: 12.5px 32px 12.5px 14px;
}

.b-hide.glass-bgs .presale-wrape .gray-pad {
      padding: 10px 15px;
      margin-bottom: 12px;
}

.b-hide.glass-bgs .presale-wrape h4 {
      font-size: 1.5rem;
      padding-bottom: 13px;
      margin-bottom: 5px;
}

.b-hide.glass-bgs .home-hero-main {
      position: sticky;
      top: 0;
      z-index: 2;
      padding: 20px 3.25rem;
}

.b-hide.glass-bgs .presale-wrape::after {
      display: none;
}

.b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape {
      padding: 63px 0 0;
      background: #ffffff;
}

.b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame,
.b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame .frame {
      height: calc(100vh - 95px);
}

.b-hide.glass-bgs .table-wrape,
.b-hide.glass-bgs .faq-wrape {
      z-index: 9;
}

.b-hide.glass-bgs .vdo-frame {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0.25;
      display: flex;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.how-to-buy.glass-background {
      background: #ffffff url(../images/png/glassbg-full.png) repeat;
      height: calc(100vh - -25px);
}

.glass-background .how-to-buy-wrape {
      border-color: #ffffff;
      border-bottom-width: 2px;
      /* background-color: #fff9; */
      -webkit-backdrop-filter: blur(3px);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 30px;
      min-height: calc(100vh - 85px);
      align-items: center;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road.glass-effect {
      background: #ffffff url(../images/png/glassbg-full.png) repeat;
      background-position: bottom;
      background-size: cover;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road.glass-effect .roadmap-wrape {
      border-color: #ffffff;
      border-bottom-width: 2px;
      /* background-color: #fff9; */
      -webkit-backdrop-filter: blur(1px);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 30px;
      min-height: calc(100vh - 85px);
      align-items: center;
}

.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road.glass-effect p,
.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road.glass-effect h4 {
      color: var(--black);
}

.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-prev,
.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-next {
      background: var(--green);
}

.b-hide.glass-bgs .roadmap-wrape .swiper-pagination-progressbar.swiper-pagination-horizontal {
      background: var(--green);
}

.b-hide.glass-bgs .roadmap-wrape .swiper-pagination-progressbar.swiper-pagination-horizontal span {
      background: var(--black);
}

.b-hide.glass-bgs .roadmap-wrape .roadmap-card-block.is-full {
      border: 1px solid rgba(0, 0, 0, 0.4);
}

.b-hide.glass-bgs .roadmap-wrape .title-wrape p::before {
      background: var(--blue);
}

#presaleSummary ul li {
      margin-bottom: 35px;
      flex-direction: column;
}

.b-hide.glass-bgs .table-wrape .container .MuiPaper-rounded {
      border-radius: 16px;
}

video {
      pointer-events: none;
}

.echarts-for-react {
      display: flex;
      justify-content: center;
}

.mac.safari.b-hide.glass-bgs .faq-wrape .title-wrape h4 {
      font-weight: 500;
}




.home-hero-main .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      gap: 20px;
}

.tall.b-hide.glass-bgs .container {
      max-width: 90%;
}

.tall.b-hide.glass-bgs .presale-wrape {
      padding: 2rem;
      max-width: inherit;
      position: relative;
      background: #ffffff;
      border-radius: 33px;
      width: 36%;
}

.tall.b-hide.glass-bgs .presale-wrape h4 {
      font-family: 'violetsans', sans-serif;
      font-weight: 400;
      font-size: 2rem;
      text-transform: uppercase;
      padding-bottom: 20px;
      position: relative;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;
}

.tall.b-hide.glass-bgs .presale-wrape .text-wrape {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0px 0 10px;
}

.tall.b-hide.glass-bgs .presale-wrape .gray-pad p {
      font-size: 17px;
}

.tall.b-hide.glass-bgs .presale-wrape .gray-pad {
      background: #f6f6f6;
      border-radius: 16px;
      padding: 18px 24px;
      margin-bottom: 20px;
}

.tall.b-hide.glass-bgs .input-pad input {
      border-radius: 6px;
      width: 100%;
      padding: 16.5px 14px;
      background-color: transparent;
      font-size: 17px;
      line-height: 20px;
      font-family: 'Inter', sans-serif;
      height: 57px;
}

.tall.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency {
      background: var(--blue);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.tall.b-hide.glass-bgs .presale-wrape .gray-pad h1 .p-currency svg {
      height: 24px;
}

.tall.b-hide.glass-bgs .progress-wrape {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      margin: 20px 0 10px;
}

.tall.b-hide.glass-bgs .progress-wrape .progress-pad {
      width: 100%;
      display: flex;
      padding: 15px 0;
}

.tall.b-hide.glass-bgs .presale-wrape p,
.tall.b-hide.glass-bgs .presale-wrape p b {
      font-size: 17px;
}

.tall.b-hide.glass-bgs .presale-wrape .footer-action .submit-btn {
      background: var(--black);
      border-radius: 30px;
      color: var(--white);
      text-transform: uppercase;
      font-weight: 500;
      padding: 5px 30px 5px 5px;
      font-size: 17px;
      gap: 10px;
}

.tall.b-hide.glass-bgs .presale-wrape .footer-action .wallet-connetct .icon {
      width: 48px;
      height: 48px;
      background: rgba(255, 255, 255, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 8px;
}

.tall .presale-wrape .text-wrape .icon,
.tall .presale-wrape .text-wrape .icon svg {
      width: 30px;
      height: 30px;
}

.tall .presale-wrape .text-wrape h6 {
      font-size: 18px;
}

.tall .hero-text-area {
      max-width: inherit;
      position: relative;
      width: 30%;
      z-index: 9;
}

.tall .hero-text-area h1,
.tall .hero-text-area h1 span {
      font-size: 40px;
      width: 300px;
}

.tall .hero-text-area p {
      font-size: 18px;
}

.tall .countdown-wrapper {
      display: flex;
      border: 1px solid #efefef;
      border-radius: 30px;
      padding: 30px;
      max-width: inherit;
      flex-wrap: wrap;
      position: relative;
      background: rgba(255, 255, 255, 1);
      width: 33%;
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: 1px solid rgba(255, 255, 255, 1);
}

.tall.b-hide.glass-bgs div.MuiSelect-select {
      min-height: 57px !important;
      padding: 12.5px 32px 12.5px 14px;
}

.tall li.MuiMenuItem-root,
.tall div.MuiSelect-select {
      font-size: 17px;
}

.tall .MuiSelect-select .dd-icon {
      width: 24px;
      height: 24px;
}

.tall .countdown-wrapper .box-head-text {
      font-size: 28px;
}

.tall .countdown-wrapper p {
      font-size: 17px;
}

.tall .countdown-pad .size-medium .flip-countdown-piece .flip-countdown-title {
      font-size: 17px !important;
}

.tall.b-hide.glass-bgs .container .container {
      max-width: 100%;
}

.tall .table-wrape .MuiTableCell-head {
      font-size: 17px;
}

.tall .MuiTableCell-root {
      font-size: 17px;
}

.tall .table-wrape .table-icon {
      width: 48px;
      height: 48px;
      padding: 12px;
}

.tall .table-wrape .table-icon svg {
      width: 24px;
      height: 24px;
}

.tall .roadmap-wrape .bullet-text {
      font-size: 18px;
      line-height: 1.5;
}

.tall.b-hide.glass-bgs .semi-parallax-wrape .roadmap-wrape .mySwiper {
      max-height: 450px;
}

.tall .roadmap-bullet {
      grid-column-gap: 0.75rem;
      grid-row-gap: 0.75rem;
      /* margin-bottom: 1.85rem; */
      margin-bottom: 1rem;
      display: flex;
}

.tall .roadmap-wrape .bullet-icon svg {
      width: 20px;
      height: 20px;
      margin-top: 5px;
}

.tall .roadmap-wrape .roadmap-title {
      font-size: 21px;
}

.tall .roadmap-wrape .title-wrape p {
      font-size: 13px;
      padding-left: 13px;
      margin-bottom: 10px;
}

.tall .roadmap-wrape .title-wrape p::before {
      width: 8px;
      height: 8px;
      top: 4px;
}

.tall .roadmap-wrape .title-wrape h4 {
      font-size: 30px;
}

.tall .roadmap-wrape .title-wrape h4:after {
      width: 159px;
      height: 43px;
}

.tall.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-prev,
.tall.b-hide.glass-bgs .roadmap-wrape .controls .swiper-button-next {
      width: 48px;
      height: 48px;
}

.tall .modal-wrape .modal-pad {
      max-width: 650px;
}

.tall .modal-wrape .modal-pad h2,
.tall .modal-wrape .modal-pad h2 span.frame {
      font-size: 44px;
}

.tall .modal-wrape .modal-pad h2 span.frame::after {
      width: 360px;
      height: 56px;
      top: -10px;
}

.tall .modal-wrape .modal-pad p {
      font-size: 18px;
}

.tall .modal-wrape .modal-pad a.modal-close {
      width: 36px;
      height: 36px;
      padding: 10px 11px 0;
}

.tall .how-to-buy-wrape p.head-label {
      font-size: 13px;
      padding-left: 13px;
}

.tall .how-to-buy-wrape p.head-label::before {
      width: 8px;
      height: 8px;
      top: 4px;
}

.tall .how-to-buy-wrape h4,
.tall .how-to-buy-wrape h4 span {
      font-size: 30px;
}

.tall .how-to-buy-wrape .left-section {
      width: 330px;
      padding-right: 10px;
}

.tall .how-to-buy-wrape .right-section {
      width: calc(100% - 330px);
}

.tall .how-to-buy-wrape .title-wrape p {
      font-size: 16px;
      line-height: 1.5;
}

.tall .how-to-buy-wrape h4:after {
      width: 286px;
      height: 47px;
}

.tall .how-to-buy-wrape .navlink b {
      font-size: 24px;
}

.tall .how-to-buy-wrape .navlink span {
      font-size: 15px;
}

.tall .how-to-buy-wrape .right-section h3 {
      font-size: 28px;
}

.tall .how-to-buy-wrape .right-section li b {
      font-size: 18px;
}

.tall .how-to-buy-wrape .right-section ul,
.tall .how-to-buy-wrape .right-section li {
      font-size: 18px;
      line-height: 1.5;
}

.tall .faq-wrape .title-wrape h4 {
      font-size: 34px;
}

.tall .faq-wrape .title-wrape p.head-label {
      font-size: 13px;
      padding-left: 13px;
}

.tall .faq-wrape .title-wrape p.head-label::before {
      width: 8px;
      height: 8px;
      top: 4px;
}

.tall .faq-wrape .title-wrape p {
      font-size: 16px;
}

.tall .faq-wrape .faq-section .number {
      font-size: 18px;
}

.tall .faq-wrape .faq-section .head-label {
      font-size: 18px;
}

.tall .faq-wrape .accr-pad .MuiAccordionDetails-root p {
      font-size: 15px;
}

.tall .faq-wrape .title-wrape h4:after {
      width: 180px;
      height: 58px;
      bottom: -8px;
}

.tall .chart-wrape {
      height: calc(100% - 390px);
}

.tall .chart-wrape .chart-section {
      height: calc(100% - 130px);
}

.tall .chart-wrape .chart-section .App {
      height: 100%;
}

.tall .echarts-for-react {
      min-height: 100%;
}

.tall .chart-wrape p.head-label {
      font-size: 13px;
      padding-left: 13px;
      gap: 8px;
      display: flex;
      justify-content: center;
}

.tall .chart-wrape p.head-label::before {
      position: relative;
      left: inherit;
      width: 8px;
      height: 8px;
      top: 6px;
}

.tall .chart-wrape h4 {
      font-size: 34px;
}

.tall .chart-wrape h4:after {
      width: 180px;
      height: 58px;
      bottom: -10px;
}

.tall .chart-wrape .title-wrape p {
      font-size: 16px;
}

.tall.b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road .container {
      width: calc(100% - 70px);
      max-width: 100%;
}

svg text {
      stroke-width: 0 !important;
}

.mac.safari h1,
.mac.safari h2,
.mac.safari h3,
.mac.safari h4,
.mac.safari h5,
.mac.safari h6,
.mac.safari b {
      font-weight: 500;
}

body.sticky-glass-bgs {
      background-image: url(../images/png/glassbg-full.png);
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
}

body.sticky-glass-bgs header {
      background: none;
}

body.sticky-glass-bgs header.is-sticky {
      background: #ffffff;
}

.my-account-page {
      width: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
}

.stat-box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
}

.stat-box .stat {
      display: flex;
      border-radius: 16px;
      padding: 15px;
      background: rgba(255, 255, 255, 1);
      background: rgba(255, 255, 255, 0.25);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.1);
      backdrop-filter: blur(3px);
      -webkit-backdrop-filter: blur(3px);
      border: 1px solid rgba(255, 255, 255, 1);
      margin-bottom: 30px;
      justify-content: space-between;
      align-items: center;
}

.card-claim-btn-main {
      margin-left: 15px;
}

.card-claim-btn-main button.btn {
      padding: 10px 9px;
      font-size: 12px;
}

.stat-box .p-currency {
      background: var(--blue);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
}

.stat.unlock .p-currency {
      background: var(--green);
}

.stat.available .p-currency {
      background: var(--black);
}

.stat-box .p-currency svg {
      height: 24px;
}

.stat-box h6 {
      font-size: 30px;
      font-weight: 600;
      font-family: var(--headfont);
      position: relative;
}

.stat-box .claim-lable {
      background: var(--blue);
      padding: 4px 15px 5px 15px;
      border-radius: 14px;
      display: inline-block;
      width: auto;
      position: absolute;
      right: 0;
      line-height: 1.25;
}

.stat-box .claim-lable span {
      color: var(--white);
      font-weight: 500;
      font-size: 13px;
}

.stat-box p {
      color: var(--black);
      opacity: 0.87;
      font-family: var(--bodyfont);
}

.stat-box .text-wrape {
      width: calc(100% - 60px);
}

.title-wrapes {
      width: 100%;
      display: flex;
      margin-bottom: 30px;
      margin-top: 20px;
}

.title-wrapes h1 {
      color: var(--black);
      font-family: var(--headfont);
      font-size: 30px;
      font-weight: 600;
}

.header-account-wrape {
      display: flex;
}

.header-account-wrape .user-base {
      background: var(--black);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      overflow: hidden;
}

.header-account-wrape .user-base img {
      max-width: 100%;
}

.header-account-wrape .user-base svg {
      height: 16px;
}

.header-cust-col.right-wrape {
      display: flex;
      gap: 1rem;
}

.header-nav-li .address-pad {
      border: 1px solid #dbdbdb;
      border-radius: 30px;
      padding: 4px 0;
}

table .actions {
      text-align: right;
}

.table-btn {
      width: 34px;
      height: 34px;
      background: var(--blue);
      border-radius: 8px;
      padding: 9px;
      display: inline-block;
      cursor: pointer;
}

.table-btn svg {
      width: 16px;
      height: 16px;
}

.how-to-buy-wrape .navlink {
      margin-top: 30px;
}

/* my style end here */
.text-center {
      text-align: center;
}


.accordion-box {
      overflow-y: scroll;
      /* height: calc(650px - 30px); */
      border-radius: 30px;
}

.accordion-box::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
}

.accordion-box::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.accordion-box::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: none;
}

.table-wrape table {
      /* border: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%; */
      /* table-layout: fixed; */
}

.table-wrape table tr {
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      padding: .35em;
}

.table-wrape table th,
.table-wrape table td {
      /* padding: .625em; */
      text-align: center;
}

.table-wrape table th {
      font-size: .85em;
      letter-spacing: .1em;
      text-transform: uppercase;
}

@media screen and (max-width: 767px) {
      .top-up-modal-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
      }

      .iframe-wrap .main-iframe {
            width: 400px;
            height: 600px;
      }

      /* 525 */
      .table-wrape table {
            border: 0;
            min-width: 100%;
      }

      .container.table-scroll {
            overflow-y: scroll;
            height: calc(600px - 110px);
      }

      .b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame,
      .b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape .box-frame .frame {
            /* overflow-y: scroll; */
            height: auto;
            min-height: auto;
            background-size: cover;
            margin-bottom: 16px;
      }

      .how-to-buy-wrape .right-section ul {
            margin-bottom: 0px;
      }

      .table-wrape table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
      }

      .table-wrape table tr {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin-bottom: .625em;
      }

      .table-wrape table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            width: 100%;
            text-align: start;
      }

      .table-wrape table td::before {
            width: 100%;
            text-align: start;
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
      }

      .css-34nofg-MuiTableRow-root:last-child td,
      .css-34nofg-MuiTableRow-root:last-child th {
            border-bottom: 1px solid #ddd !important;
      }

      .table-wrape table tr td:first-child {
            grid-column: 1 / -1;
            display: none;
      }

      .table-wrape table tr td:nth-child(4),
      .css-34nofg-MuiTableRow-root:last-child td:nth-child(4) {
            border-bottom: none !important;
      }

      .table-wrape table tr td:nth-child(5),
      .css-34nofg-MuiTableRow-root:last-child td:nth-child(5) {
            border-bottom: none !important;
      }

      .b-hide.glass-bgs .table-wrape .container .MuiPaper-rounded {
            border-radius: 0;
      }

      .b-hide.glass-bgs .home-hero-main {
            position: unset;
            height: auto !important;
      }

      .b-hide.glass-bgs .semi-parallax-wrape {
            position: unset;
            height: auto;
      }

      .glass-bgs .glass-bg {
            position: unset;
      }

      .accordion-box {
            overflow: hidden;
            height: unset;
      }

      .b-hide.glass-bgs .inner-section {
            height: auto;
      }

      .b-hide.glass-bgs .how-to-buy-wrape .right-section .section:last-child {
            padding: 0 0 0px;
      }

      .semi-parallax-wrape .frame.glass,
      .semi-parallax-wrape .box-frame .frame,
      .glass-background .how-to-buy-wrape,
      .b-hide.glass-bgs .semi-parallax-wrape .box-frame,
      .b-hide.glass-bgs .semi-parallax-wrape .box-frame .frame.road.glass-effect .roadmap-wrape {
            box-shadow: none;
            border-radius: 0px;
      }

      #presaleSummary ul li {
            margin-bottom: 14px;
      }

      .b-hide.glass-bgs .home-hero-main {
            padding-bottom: 26px !important;
      }

      .b-hide.glass-bgs .header-main.is-sticky~.semi-parallax-wrape {
            padding: 26px 0px;
      }

      .b-hide.glass-bgs .semi-parallax-wrape .glass-bg {
            display: none;
      }
}

.cus-accordion .cus-accordion-button {
      min-height: 64px;
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #e5e5e5;
}

.cus-accordion .cus-accordion-detail {
      /* padding: 8px 16px 16px; */
      background: white;
      height: 0;
      padding: 0;
      transition: all .5s ease;
}

.cus-accordion:hover .cus-accordion-detail {
      height: 100%;
      transition: height .25s;
      /* padding: 8px 16px 16px; */

}

.cus-accordion-button .down-ic {
      width: 11px;
      transition: all .5s ease;
}

.cus-accordion:hover .down-ic {
      transform: rotate(180deg);
      /* transition: all .5s ease; */
}

.cus-accordion-detail .roadmap-bullet-block {
      padding: 8px 16px 16px;
}

/* .chart-img-section .chart-img{
      max-width: 570px;
} */
.chart-detail-col,
.chart-detail-section {
      display: flex;
      align-items: center;
      justify-content: center;
}

.chart-detail-section {
      /* justify-content: space-around; */
      width: 100%;
}

.chart-detail-section .detail-col-1 {
      margin: 0 30px 0 0;
}

.detail-col p {
      font-size: 16px;
      font-weight: 400;
      color: black;
      margin: 0 10px 0px 0;
      min-width: 197px;
}

.detail-wrapper {
      display: flex;
      align-items: center;
      margin: 0 0 15px 0;
}

.detail-wrapper span {
      padding: 8px;
      /* background: #5470c6; */
      border-radius: 10px;
      color: white;
      font-size: 14px;
      min-width: 64px;
      text-align: center;
}

.partnership-text span {
      background: #5470c6;
}

.tresury-text span {
      background: #91cc75;
}

.advisors-text span {
      background: #f9c859;
}

.ecosystem-text span {
      background: #ee6666;
}

.founder-text span {
      background: #73c0de;
}

.pre-seed-text span {
      background: #ea7ccc;
}

.seed-text span {
      background: #9a60b4;
}

.strategic-text span {
      background: #fc8452;
}

.main-iframe {
      width: 560px;
      min-height: 580px;
}

.top-up-modal-wrap {
      /* height: 100%; */
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
}

.loader {
      width: 25px;
      height: 25px;
      border: 3px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
}

.loader1 {
      width: 25px;
      height: 25px;
      margin-left: 20px;
      border: 3px solid #000;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
}

@keyframes rotation {
      0% {
            transform: rotate(0deg);
      }

      100% {
            transform: rotate(360deg);
      }
}

.chart-wrape p.head-label.tokenomic-title {
      font-size: 18px;
}

.chart-wrape p.head-label.tokenomic-title::before {
      left: calc(50% - 55px);
}

.swipper-main-wrape .swiper-button-prev {
      left: initial;
      top: -17%;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #2b5af3;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      opacity: 1;
      right: 60px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
}


.swipper-main-wrape .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
      content: "";
      background-image: url("../images/svg/next-arrows.svg");
      height: 11px;
      width: 7px;
      background-repeat: no-repeat;
      margin: auto;
      top: 38%;
      position: absolute;
      left: 0;
      right: 0;
}

.swipper-main-wrape .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
      content: "";
      background-image: url("../images/svg/prev-arrows.svg");
      height: 11px;
      width: 7px;
      background-repeat: no-repeat;
      margin: auto;
      top: 38%;
      position: absolute;
      left: 0;
      right: 0;
}




.claim-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px;
}

.claim-right-main p {
      font-size: 11px;
      color: #000;
}

.claim-right-main {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 11px;
      color: #000;
}

.claim-btn-flex .view-content-btn {
      font-size: 12px;
      background-color: #000;
      border: 1px solid #e79654;
      font-weight: 700;
      line-height: 20px;
      color: #e79654;
      border-radius: 5px;
      padding: 2px !important;
}

.claim-flex .claim-left-main button.btn {
      font-size: 12px;
      background-color: #000;
      border: 1px solid #e79654;
      font-weight: 700;
      line-height: 20px;
      color: #e79654;
      border-radius: 5px;
      padding: 5px !important;
}

.chart-wrape p.head-label.tokenomic-title {
      font-size: 18px;
}

/* .chart-wrape p.head-label.tokenomic-title::before{
      left: calc(50% - 53px) ;
      top: 6px;
  } */


.swipper-main-wrape .swiper-button-next {
      top: -17%;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #2b5af3;
      height: 48px;
      width: 48px;
      border-radius: 50%;
      opacity: 1;
      right: 1px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
}

.swipper-main-wrape .swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
      content: "";
      background-image: url("../images/svg/next-arrows.svg");
      height: 11px;
      width: 7px;
      background-repeat: no-repeat;
      margin: auto;
      top: 38%;
      position: absolute;
      left: 0;
      right: 0;
}

.swipper-main-wrape .swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
      content: "";
      background-image: url("../images/svg/prev-arrows.svg");
      height: 11px;
      width: 7px;
      background-repeat: no-repeat;
      margin: auto;
      top: 38%;
      position: absolute;
      left: 0;
      right: 0;
}

.inner-section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 60px;
}

.head-line-flex {
      display: flex;
      gap: 10px;
}

video::-webkit-media-controls {
      display: none;
}


.modal-body-Hero {

      width: 100%;
      padding: 20px 20px 0px;
}




.clock {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 2rem;
}

.clock__display {
      height: 80px;
      position: relative;
      width: 80px;
}

.clock__text {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
}

.clock__amount {
      font-size: 1.75rem;
}

.clock__unit {
      font-size: 0.75rem;
}

.circle {
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7);
}